import React from "react";
import useTopSalesReps from "../../hooks/assets-under-management/useTopSalesReps";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";
import TopSales from "../global/top-sales";

const AUMTopSales = () => {
  const [filters] = useAtom(filtersAtom);
  const { status, data } = useTopSalesReps(filters);
  const sectionTitle = "Top Sales Rep";

  return <TopSales data={data} status={status} sectionTitle={sectionTitle} />;
};

export default AUMTopSales;
