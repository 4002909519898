import { useQuery } from "react-query";
import axios from "axios";
import { getAPIURL } from "../core/api";
import { Filters } from "../states/filters";
import moment from "moment";
import { createCheckboxesFromJSON } from "../core/sidebar";
import { removeTimeFromDate } from "../utils/utils";

export default function useBackendFilters() {
  return useQuery(["backend-filters"], () => fetch());
}

/**
 * @api {post} /filters Filters
 * @apiGroup Assets Under Management
 *
 * @apiParam {String} token
 *
 * @apiSuccessExample {json} Success-Response:
 *  HTTP/1.1 200 OK
 *  {
 *     startDate: "2020-03-27T07:33:39.259Z" string date in iso format;
 *     endDate: "2021-04-27T07:33:39.259Z" string date in iso format;
 *     branches: [ {name: string, id: string} ]
 *     businesses: [ {name: string, id: string} ]
 *     reps: [ {name: string, id: string} ]
 *     securities: [ {name: string, id: string} ]
 *  }
 *  @apiSampleRequest off
 */
const fetch = async (): Promise<Filters> => {
  const response = await axios.post(getAPIURL("/filters"), {});
  const endDate = removeTimeFromDate(moment(response.data.endDate).add(1, "days").toDate());
  const YTDStartDate = moment(response.data.endDate).startOf("year").toDate();
  const YTDEndDate = moment(endDate).toDate();
  const MTDStartDate = moment(response.data.endDate).startOf("month").toDate();
  const MTDEndDate = moment(endDate).toDate();
  const QTDStartDate = moment(response.data.endDate).startOf("month").startOf("quarter").toDate();
  const QTDEndDate = moment(endDate).toDate();
  const PQTDStartDate = moment(response.data.endDate).subtract(1, "quarter").startOf("quarter").toDate();
  const PQTDEndDate = moment(endDate).subtract(1, "quarter").endOf("quarter").toDate();

  let filters: Filters = {
    customRangeDate: [],
    minDate: moment(response.data.startDate).toDate(),
    maxDate: endDate,
    startDate: moment(response.data.endDate).startOf("quarter").subtract(1, "day").toDate(),
    endDate: endDate,
    branches: response.data.branches ? createCheckboxesFromJSON(response.data.branches) : [],
    reps: createCheckboxesFromJSON(response.data.reps),
    businesses: createCheckboxesFromJSON(response.data.businesses),
    securities: createCheckboxesFromJSON(response.data.securities),
    accounts: response.data.accounts ? createCheckboxesFromJSON(response.data.accounts) : [],
    YTDStartDate,
    YTDEndDate,
    MTDStartDate,
    MTDEndDate,
    QTDStartDate,
    QTDEndDate,
    PQTDStartDate,
    PQTDEndDate,
  };

  return { ...filters };
};
