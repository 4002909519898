import React, { useEffect, ReactNode } from "react";
import Header from "../header/header";
import Sidebar from "../sidebar/sidebar";
import MainContainer from "./main-container";
import { Route, HashRouter as Router, Navigate, Routes } from "react-router-dom";
import AssetsUnderManagement from "../../pages/assets-under-management/assets-under-management";
import ProductionDashboard from "../../pages/production-dashboard/production-dashboard";
import { routes } from "../../core/routes";
import { useMutateFilters } from "../../hooks/useMutateFilters";
import { filtersAtom } from "../../states/filters";
import { useAtom } from "jotai";

interface DataPortalLayoutProps {
  children?: ReactNode;
}

const DataPortalLayout: React.FC<DataPortalLayoutProps> = ({ children }) => {
  const { filters: mutatedFilters } = useMutateFilters();
  const [, setFilters] = useAtom(filtersAtom);

  useEffect(() => {
    setFilters(mutatedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutatedFilters]);

  return (
    <Router>
      <Header />
      <MainContainer>
        <Sidebar />
        <Routes>
          <Route path="/" element={
            <Navigate replace to={routes.assetsUnderManagement} />
          } />
          <Route path={routes.assetsUnderManagement} element={
            <AssetsUnderManagement />
          } />
          <Route path={routes.productionDashboard} element={
            <ProductionDashboard />
          } />
        </Routes>
      </MainContainer>
    </Router>
  );
};

export default DataPortalLayout;
