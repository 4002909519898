/* eslint-disable */
import React, { useEffect, useState } from "react";
import Accordion from "./accordion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DatePickerContainer from "./date-picker-container";
import DateInput from "./date-input";
import { useAtom } from "jotai";
import { Filters, filtersAtom } from "../../states/filters";
import { Text } from "@chakra-ui/react";
import { RadioButtonItem } from "../../types/form";

interface DatePickerProps {
  startDate: Date;
  setStartDate: Function;
  endDate: Date;
  setEndDate: Function;
  filters: Filters;
  onResetCustomDateRange: Function;
}

const Dates: React.FC<DatePickerProps> = ({ startDate, setStartDate, endDate, setEndDate, onResetCustomDateRange }) => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [startDateInput, setStartDateInput] = useState<string | null>(null);
  const [endDateInput, setEndDateInput] = useState<string | null>(null);

  useEffect(() => {
    setGlobalFilters(startDate, endDate);
  }, [startDate, endDate]);

  const onChange = (dates: any) => {
    const [start, end] = dates;

    setStartDateInput(null);
    setEndDateInput(null);
    setStartDate(start);
    setEndDate(end);

    onResetCustomDateRange();
    setGlobalFilters(start, end);
  };

  const setGlobalFilters = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      setFilters({
        ...filters,
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
        customRangeDate: filters.customRangeDate.map((range) => ({ ...range, checked: false })),
      });
    }
  };

  return (
    <>
      <Text textStyle="sortTitle" fontWeight="700" color={"white"} mb={3}>
        Dates
      </Text>
      <DateInput
        startDate={startDate}
        endDate={endDate}
        setGlobalFilters={setGlobalFilters}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setStartDateInput={setStartDateInput}
        setEndDateInput={setEndDateInput}
        startDateInput={startDateInput}
        endDateInput={endDateInput}
      />
      <DatePickerContainer>
        <DatePicker
          selected={endDate}
          onChange={onChange}
          endDate={endDate}
          startDate={startDate}
          minDate={filters.minDate}
          maxDate={filters.maxDate}
          selectsRange
          inline
        />
      </DatePickerContainer>
    </>
  );
};

export default Dates;
