import React from "react";
import { theme } from "../../../theme";
import { getXAdjust } from "./graph-helper";
import { formatDates } from "../../../utils/utils";

interface GraphCashFlowProps {
  movementsData: any;
  branchData: any;
  cashFlowScale: Function;
  xScale: Function;
  accessors: any;
  graphConstants: any;
  xAxisY: number;
}

const GraphCashFlowBars: React.FC<GraphCashFlowProps> = ({
  movementsData,
  cashFlowScale,
  xScale,
  accessors,
  graphConstants,
  branchData,
  xAxisY,
}) => {
  return (
    <>
      {movementsData.map((movement: any, index: number) => {
        const depositHeight = Math.abs(cashFlowScale(Math.abs(parseFloat(movement.deposit))) - cashFlowScale(0));
        const withdrawalHeight = Math.abs(cashFlowScale(Math.abs(parseFloat(movement.withdrawal))) - cashFlowScale(0));
        const acatsInHeight = Math.abs(cashFlowScale(Math.abs(parseFloat(movement.acatIn))) - cashFlowScale(0));
        const acatsOutHeight = Math.abs(cashFlowScale(Math.abs(parseFloat(movement.acatOut))) - cashFlowScale(0));

        const xAdjust = getXAdjust(movementsData.length, index);

        return (
          <g key={`g-${index}`}>
            <text
              fill={theme.colors.grey_50}
              fontSize={dateAttributes.fontSize}
              fontFamily={theme.fonts.heading}
              x={xScale(accessors.getIndex(movement, movementsData)) - dateAttributes.xModifier}
              y={xAxisY}
            >
              {formatDates(branchData[index].date)}
            </text>
            <rect
              x={xScale(accessors.getIndex(movement, movementsData)) + BarConstants.depositXModifier + xAdjust}
              y={cashFlowScale(0) - depositHeight}
              width={cashFlowBarWidth}
              fill={theme.colors.accent_3_50}
              height={depositHeight}
              stroke={theme.colors.accent_3_900}
            />
            <rect
              x={xScale(accessors.getIndex(movement, movementsData)) + BarConstants.withdrawalXModifier + xAdjust}
              y={cashFlowScale(0)}
              width={cashFlowBarWidth}
              fill={theme.colors.accent_3_900}
              stroke="transparent"
              height={withdrawalHeight}
            />
            <rect
              x={xScale(accessors.getIndex(movement, movementsData)) + BarConstants.acatInXModifier + xAdjust}
              y={cashFlowScale(0) - acatsInHeight}
              width={cashFlowBarWidth}
              fill={theme.colors.white}
              height={acatsInHeight}
              stroke={theme.colors.primary50}
            />
            <rect
              x={xScale(accessors.getIndex(movement, movementsData)) + BarConstants.acatOutXModifier + xAdjust}
              y={cashFlowScale(0)}
              width={cashFlowBarWidth}
              fill={theme.colors.primary900}
              height={acatsOutHeight}
              stroke="transparent"
            />
          </g>
        );
      })}
    </>
  );
};

const cashFlowBarWidth = 6;
const BarConstants = {
  width: 6,
  depositXModifier: -9,
  withdrawalXModifier: -3,
  acatInXModifier: 2,
  acatOutXModifier: 2,
};

const dateAttributes = {
  fontSize: 11,
  xModifier: 12,
  yModifier: 20,
};

export default GraphCashFlowBars;
