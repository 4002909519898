import React, {ReactNode} from "react";
import styled from "styled-components";
import { theme } from "../../theme";
interface SidebarDatePickerProps {
  children: ReactNode;
}

const DatePickerContainer: React.FC<SidebarDatePickerProps> = ({ children }) => {
  return <CustomDatePicker>{children}</CustomDatePicker>;
};

const CustomDatePicker = styled.div`
  * {
    background-color: transparent;
    border: none;
    color: white;
    font-family: Roboto, sans-serif;
  }

  .react-datepicker {
    width: 180px !important;
    font-size: 12px !important;
  }

  .react-datepicker__month {
    margin: 8px 0 0 0;
  }

  .react-datepicker__current-month {
    font-weight: normal;
    font-size: 12px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 20px;
    line-height: 20px;
    margin-bottom: 8px;
    font-size: 12px;
    padding: 1px;
  }

  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__navigation {
    border: 0.45rem solid transparent;
    height: auto;
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
  }

  .react-datepicker__navigation-icon {
    display: none;
  }

  .react-datepicker__navigation--previous {
    left: 0;
    border-right-color: ${theme.colors.accent_1_900};
  }

  .react-datepicker__navigation--next {
    right: 0;
    border-left-color: ${theme.colors.accent_1_900};
  }

  .react-datepicker__day-names {
    display: none;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--in-selecting-range {
    background-color: ${theme.colors.accent_1_900} !important;
    color: ${theme.colors.primary900} !important;
    font-weight: normal;
    border-radius: 1rem;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background-color: ${theme.colors.accent_1_900} !important;
    color: ${theme.colors.primary900} !important;
    font-weight: normal;
    border-radius: 1rem;
  }

  .react-datepicker__day--in-range {
    background-color: ${theme.colors.grey_900};
    color: ${theme.colors.accent_1_900};
    font-weight: normal;
    border-radius: 1rem;
  }

  .react-datepicker__day--disabled {
    color: gray;
    pointer-events: none;
  }
`;

export default DatePickerContainer;
