import React, { useEffect, useState } from "react";
import { Accordion, Flex, VStack } from "@chakra-ui/react";
import { filtersAtom, initialFiltersAtom } from "../../states/filters";
import { currentDashboard } from "../../states/routes";
import CheckboxGroup from "./checkbox-group";
import Dates from "./dates";
import Reset from "./reset";
import { useAtom } from "jotai";
import { useAccordion } from "../../hooks/useAccordion";
import { FiltersName, FiltersType, MenuOptions, Privileges } from "../../enums/enums";
import { useMutateFilters } from "../../hooks/useMutateFilters";
import { useRadioButtons } from "../../hooks/useRadioButtons";
import { CheckboxItem, RadioButtonItem } from "../../types/form";
import { privileges } from "../../states/privileges";
import CustomDateRange from "./custom-date-range";
import moment from "moment";

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [initialFilters] = useAtom(initialFiltersAtom);
  const [dashboard] = useAtom(currentDashboard);
  const [rol] = useAtom(privileges);
  const { onSetCheckboxesGroup } = useMutateFilters();
  const { radioButtons } = useRadioButtons();
  const [customDatesRange, setCustomDatesRange] = useState<RadioButtonItem[]>(radioButtons);
  const [accounts, setAccounts] = useState<CheckboxItem[]>(filters.accounts!);
  const [branches, setBranches] = useState<CheckboxItem[]>(filters.branches!);
  const [reps, setReps] = useState(filters.reps);
  const [securities, setSecurities] = useState(filters.securities);
  const [businesses, setBusinesses] = useState(filters.businesses);
  const [startDate, setStartDate] = useState(filters.startDate);
  const [endDate, setEndDate] = useState(filters.endDate);
  const { onSetIndexByClickingCharts, onSetIndexByClickingFilter, index } = useAccordion();
  const [radioButtonValue, setRadioButtonValue] = useState<string>();

  const onReset = () => {
    if (initialFilters.accounts) setAccounts(initialFilters.accounts);
    if (initialFilters.branches) setBranches(initialFilters.branches);
    if (dashboard === MenuOptions.ASSETS_UNDER_MANAGEMENT) {
      const AUMStartDate = moment().startOf("year").subtract(1, "days").toDate();
      setStartDate(AUMStartDate);
    }
    if (dashboard === MenuOptions.PRODUCTION_DASHBOARD) {
      const PDStartDate = moment().startOf("year").toDate();
      setStartDate(PDStartDate);
    }
    setFilters(initialFilters);
    setCustomDatesRange(radioButtons);
    setReps(initialFilters.reps);
    setSecurities(initialFilters.securities);
    setBusinesses(initialFilters.businesses);
    setEndDate(initialFilters.endDate);
    setRadioButtonValue("");
  };

  useEffect(() => {
    if (filters.accounts) setAccounts(filters.accounts);
    if (filters.branches) setBranches(filters.branches);
    setReps(filters.reps);
    setSecurities(filters.securities);
    setBusinesses(filters.businesses);
    onSetIndexByClickingCharts(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const defaultStartDate = moment(filters.endDate).startOf("quarter").subtract(1, "day").toDate();
    setStartDate(defaultStartDate);
    onSetCheckboxesGroup({ ...filters, startDate: defaultStartDate }, FiltersType.REPS, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkboxGroups = () => {
    let groups: any[] = [];

    if (rol) {
      const isAdvisor = rol === Privileges.SALES_REPRESENTATIVE;

      if (!isAdvisor) {
        groups = [
          {
            items: branches,
            set: setBranches,
            name: FiltersName.BRANCH_OFFICE,
            filterKey: "branches",
            allName: "All Offices",
            open: index === 2,
          },
          {
            items: reps,
            set: setReps,
            name: FiltersName.SALES_REP,
            filterKey: "reps",
            allName: "All Reps",
            open: index === 3,
          },
          {
            items: businesses,
            set: setBusinesses,
            name: FiltersName.BUSINESS_TYPE,
            filterKey: "businesses",
            allName: "All Businesses",
            open: index === 4,
          },
          {
            items: securities,
            set: setSecurities,
            name: FiltersName.SECURITY_TYPE,
            filterKey: "securities",
            allName: "All Securities",
            open: index === 5,
          },
        ];
      } else {
        groups = [
          {
            items: reps,
            set: setReps,
            name: FiltersName.SALES_REP,
            filterKey: "reps",
            allName: "All Reps",
            open: index === 2,
          },
          {
            items: businesses,
            set: setBusinesses,
            name: FiltersName.BUSINESS_TYPE,
            filterKey: "businesses",
            allName: "All Businesses",
            open: index === 3,
          },
          {
            items: securities,
            set: setSecurities,
            name: FiltersName.SECURITY_TYPE,
            filterKey: "securities",
            allName: "All Securities",
            open: index === 4,
          },
          {
            items: accounts,
            set: setAccounts,
            name: FiltersName.ACCOUNTS,
            filterKey: "accounts",
            allName: "All Accounts",
            open: index === 5,
          },
        ];
      }
    }

    return groups;
  };

  const onResetCustomDateRange = () =>
    setCustomDatesRange(customDatesRange.map((range) => ({ ...range, checked: false })));

  return (
    <Flex bg="primary900" width={250} padding={9} alignItems="flex-start" direction="column" className="sidebar">
      <VStack align="start" spacing={4}>
        <Reset onReset={onReset} />
        <Accordion onClick={(e: any) => onSetIndexByClickingFilter(e.target.innerHTML)}>
          <Dates
            filters={filters}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            onResetCustomDateRange={onResetCustomDateRange}
          />
          <CustomDateRange
            radiobuttons={customDatesRange}
            setRadioButtons={setCustomDatesRange}
            filtersID={`customRangeDate`}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setRadioButtonValue={setRadioButtonValue}
            value={radioButtonValue}
          />
          {checkboxGroups().map((group, index) => (
            <CheckboxGroup
              key={index}
              checkboxes={group.items}
              setCheckboxes={group.set}
              name={group.name}
              filtersID={group.filterKey}
              allCheckboxName={group.allName}
              isOpen={group.open}
            />
          ))}
        </Accordion>
      </VStack>
    </Flex>
  );
};

export default Sidebar;
