import React, { useEffect } from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { useAtom } from "jotai";
import GraphHeader from "../../global/graph/graph-header";
import { filtersAtom } from "../../../states/filters";
import useGraphData from "../../../hooks/production-dashboard/useGraphData";
import GraphGrouping, { GraphGroupingOption } from "../../global/graph/graph-grouping";
import { getSelectedGraphGroupingOption, onClickGraphGroupingOption } from "../../../hooks/useGraphGrouping";
import { privileges } from "../../../states/privileges";
import { useGraphGroupingOptions } from "../../../hooks/useGraphGroupingOptions";
import Graph from "./graph";
import NoDataFound from "../../../components/graph/no-data-found";

interface GraphLoaderProps {}
const GraphLoader: React.FC<GraphLoaderProps> = () => {
  const [filters] = useAtom(filtersAtom);
  const [rol] = useAtom(privileges);
  const {
    defaultGraphGroupingOptions,
    setGraphGroupingOptions,
    setGraphGroupingOptionsByRol,
  } = useGraphGroupingOptions();
  const { status, data } = useGraphData(filters, getSelectedGraphGroupingOption(defaultGraphGroupingOptions).value);
  const hasAreasData = data?.areasData.length;
  const hasKeys = data?.keys.length;

  const onClickGraphGroupingOptionHandler = (selectedOption: GraphGroupingOption) => {
    onClickGraphGroupingOption(defaultGraphGroupingOptions, selectedOption, setGraphGroupingOptions);
  };

  useEffect(() => {
    if (rol) setGraphGroupingOptionsByRol(rol);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rol]);

  const selectedGroupingOption = defaultGraphGroupingOptions.find((opt) => opt.selected);

  return (
    <Flex layerStyle="shadowBox" h={570}>
      <GraphHeader
        sectionTitle="Production"
        rightSide={
          <GraphGrouping options={defaultGraphGroupingOptions} onClickOption={onClickGraphGroupingOptionHandler} />
        }
      />
      <Flex justify="center" alignItems="center" flex={1}>
        {status !== "success" ? (
          <Spinner size="xl" color="accent_1_900" />
        ) : status === "success" && !hasKeys && !hasAreasData ? (
          <NoDataFound />
        ) : (
          <Graph data={data} groupingOption={selectedGroupingOption?.label!} />
        )}
      </Flex>
    </Flex>
  );
};

export default GraphLoader;
