import axios from "axios";
import { ImpersonateUserIDKey } from "./impersonate";

axios.interceptors.request.use((config) => {
  config.params = config.params || {};

  const impersonatedUser = localStorage.getItem(ImpersonateUserIDKey);
  if (impersonatedUser) {
    config.params.impersonate = impersonatedUser;
  }

  return config;
});

export const getAPIURL = (endpoint: string) => {
  return `${process.env.NODE_ENV !== "development" ? process.env.REACT_APP_BACKEND_URL : ""}/api${endpoint}`;
};

export const setupCommonHeaders = (token: string) => {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
};
