import React from "react";
import { Flex, Text } from "@chakra-ui/react";
// TODO Investigate why Fontawesome can not be installed on Dev server
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRedo } from "@fortawesome/free-solid-svg-icons";

interface ResetProps {
  onReset: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Reset: React.FC<ResetProps> = ({ onReset }) => {
  return (
    <Flex direction="row" width="100%" justify="space-between">
      <Text textStyle="sortTitle" fontWeight="700">
        Filter by:
      </Text>
      <Flex direction="row" onClick={onReset} userSelect="none" as="button">
        {/*<Box>*/}
        {/*  <FontAwesomeIcon icon={faRedo} color="white" size="xs" />*/}
        {/*</Box>*/}
        {/*<Box mr={2} />*/}
        <Text textStyle="bodySmallLink" color="accent_1_900" mt="5px">
          Reset
        </Text>
      </Flex>
    </Flex>
  );
};

export default Reset;
