import { useQuery } from "react-query";
import axios from "axios";
import { Filters } from "../../states/filters";
import { getAPIURL } from "../../core/api";
import { getFiltersForBackend } from "../../core/filters";
import { MixOfBusiness } from "../../types/mix-of-business";
export default function useGraphData(filters: Filters) {
  return useQuery(["assets-under-management-mix-of-business-graph", filters], () => fetch(filters));
}

const fetch = async (filters: Filters): Promise<MixOfBusiness> => {
  const newFilters = getFiltersForBackend(filters);
  const response = await axios.post(getAPIURL("/assets-under-management/mix-business"), {
    ...newFilters,
  });

  return response.data;
};
