import React from "react";
import { getY0, getY1 } from "./graph-helper";
import { getColorForArea, getColorForSecurities, getColorForAccounts } from "../../../core/color";
import { AreaStack } from "@visx/shape";

interface GraphAreaProps {
  keys: any;
  data: any;
  xScale: Function;
  yScale: Function;
  accessors: any;
  groupingOption: string;
}

const GraphAreas: React.FC<GraphAreaProps> = ({ keys, data, xScale, yScale, accessors, groupingOption }) => {
  return (
    <AreaStack
      keys={keys}
      data={data}
      x={(d: any) => xScale(accessors.getIndex(d.data, data))}
      y0={(d) => yScale(getY0(d)) ?? 0}
      y1={(d) => yScale(getY1(d)) ?? 0}
    >
      {({ stacks, path }) =>
        stacks.map((stack) => {
          const key = stack.key as string;
          return (
            <path
              key={`stack-${stack.key}`}
              d={path(stack) || ""}
              stroke={
                groupingOption === "Branch"
                  ? getColorForArea(key, keys.indexOf(key))
                  : groupingOption === "Security Type"
                  ? getColorForSecurities(key, keys.indexOf(key))
                  : groupingOption === "Business Type"
                  ? getColorForAccounts(key, keys.indexOf(key))
                  : getColorForArea(key, keys.indexOf(key))
              }
              fill={
                groupingOption === "Branch"
                  ? getColorForArea(key, keys.indexOf(key))
                  : groupingOption === "Security Type"
                  ? getColorForSecurities(key, keys.indexOf(key))
                  : groupingOption === "Business Type"
                  ? getColorForAccounts(key, keys.indexOf(key))
                  : getColorForArea(key, keys.indexOf(key))
              }
            />
          );
        })
      }
    </AreaStack>
  );
};

export default GraphAreas;
