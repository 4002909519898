import React from "react";
import { getXAdjust } from "./graph-helper";

interface GraphHoverBarProps {
  index: number;
  movement: any;
  xScale: Function;
  accessors: any;
  currentX: number | undefined;
  onMouseOver: Function;
  onMouseOut: Function;
  totalXEntries: number;
  barHeight: number;
  data: any;
}

const GraphHoverBar: React.FC<GraphHoverBarProps> = ({
  index,
  xScale,
  accessors,
  movement,
  currentX,
  onMouseOver,
  onMouseOut,
  totalXEntries,
  barHeight,
  data,
}) => {
  return (
    <rect
      key={index}
      x={xScale(accessors.getIndex(movement, data)) - 18 + getXAdjust(totalXEntries, index)}
      y={0}
      width={36}
      fill={currentX === index ? "#ccc" : "transparent"}
      opacity={0.2}
      height={barHeight}
      stroke="transparent"
      onMouseOver={(e) => onMouseOver(e)}
      onMouseOut={() => onMouseOut()}
    />
  );
};

export default GraphHoverBar;
