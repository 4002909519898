import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { Transaction } from "../../../../types/transaction";
import moment from "moment";
import { theme } from "../../../../theme";
import { useSortable } from "../../../../hooks/useSortable";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {ColumnHeader, ColumnHeaderCtn, Content, TextContainer} from "./styled-components";

interface GraphModalTableProps {
  isOpen: boolean;
  onClose: Function;
  data: Array<Transaction>;
}

const GraphModalTable: React.FC<GraphModalTableProps> = ({ isOpen, onClose, data }) => {
  const { onSortTransactions } = useSortable();
  const [settlementDateAsc, setSettlementDateAsc] = useState<boolean>(false);
  const [accountNumberAsc, setAccountNumberAsc] = useState<boolean>(false);
  const [accountTitleAsc, setAccountTitleAsc] = useState<boolean>(false);
  const [cusipAsc, setCusipAsc] = useState<boolean>(false);
  const [cusipDescAsc, setCusipDescAsc] = useState<boolean>(false);
  const [grossCommissionAsc, setGrossCommissionAsc] = useState<boolean>(false);
  const [transactionPriceAsc, setTransactionPriceAsc] = useState<boolean>(false);
  const [transactionQuantityAsc, setTransactionQuantityAsc] = useState<boolean>(false);

  return (
    <Modal size={"8xl"} scrollBehavior={"inside"} isOpen={isOpen} onClose={() => onClose()}>
      <ModalOverlay />
      <Content>
        <ModalHeader>{`Transactions ${
          data && data.length ? `on ${moment(data[0].trade_date).format("LL")}` : ""
        }`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!data ? (
            <TextContainer>
              <Spinner size="xl" color="accent_1_900" />
            </TextContainer>
          ) : data && data.length ? (
            <Table colorScheme={`green`}>
              <Thead>
                <Tr>
                  <ColumnHeader
                    onClick={() => {
                      setSettlementDateAsc(!settlementDateAsc);
                      onSortTransactions(`settlement_date`, data, settlementDateAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`Settlement Date`}
                      {settlementDateAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                  <ColumnHeader
                    onClick={() => {
                      setAccountNumberAsc(!accountNumberAsc);
                      onSortTransactions(`account_number`, data, accountNumberAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`Account Number`}
                      {accountNumberAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                  <ColumnHeader
                    onClick={() => {
                      setAccountTitleAsc(!accountTitleAsc);
                      onSortTransactions(`account_title`, data, accountTitleAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`Account Title`}
                      {accountTitleAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                  <ColumnHeader
                    onClick={() => {
                      setCusipAsc(!cusipAsc);
                      onSortTransactions(`cusip`, data, cusipAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`CUSIP`}
                      {cusipAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                  <ColumnHeader
                    onClick={() => {
                      setCusipDescAsc(!cusipDescAsc);
                      onSortTransactions(`cusip_desc`, data, cusipDescAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`CUSIP DESC`}
                      {cusipDescAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                  <ColumnHeader
                    onClick={() => {
                      setTransactionQuantityAsc(!transactionQuantityAsc);
                      onSortTransactions(`transaction_quantity`, data, transactionQuantityAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`Quantity`}
                      {transactionQuantityAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                  <ColumnHeader
                    onClick={() => {
                      setTransactionPriceAsc(!transactionPriceAsc);
                      onSortTransactions(`transaction_price`, data, transactionPriceAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`Price`}
                      {transactionPriceAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                  <ColumnHeader
                    onClick={() => {
                      setGrossCommissionAsc(!grossCommissionAsc);
                      onSortTransactions(`gross_comission`, data, grossCommissionAsc);
                    }}
                  >
                    <ColumnHeaderCtn>
                      {`Gross Commission`}
                      {grossCommissionAsc ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </ColumnHeaderCtn>
                  </ColumnHeader>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((transaction, index) => (
                  <Tr key={index} style={{ backgroundColor: index % 2 ? `${theme.colors.accent_1_50}` : "white" }}>
                    <Th>{moment(transaction.settlement_date).format("YYYY-MM-DD")}</Th>
                    <Th>{transaction.account_number}</Th>
                    <Th>{transaction.account_title}</Th>
                    <Th>{transaction.cusip}</Th>
                    <Th>{transaction.cusip_desc}</Th>
                    <Th isNumeric>{transaction.transaction_quantity}</Th>
                    <Th isNumeric>{transaction.transaction_price}</Th>
                    <Th>{transaction.gross_comission}</Th>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <TextContainer>
              <Text>No transactions were found</Text>
            </TextContainer>
          )}
        </ModalBody>
      </Content>
    </Modal>
  );
};

export default GraphModalTable;
