import React, {ReactNode} from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonActive?: boolean;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  showButtons?: boolean;
  children: ReactNode;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  title = "Confirm",
  confirmText = "Confirm",
  cancelText = "Cancel",
  showButtons = true,
  onConfirm,
  children,
  confirmButtonActive = true,
}) => {
  return (
    <Modal scrollBehavior={"inside"} isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Box mt={3} mb={1}>
          {children}
        </Box>
        <ModalFooter>
          {showButtons && (
            <Flex grow={1} justify="space-between">
              <Button mr={10} onClick={onClose}>
                {cancelText}
              </Button>
              <Button
                bg="accent_1_900"
                color="white"
                _hover={{ bg: "accent_3_900" }}
                onClick={onConfirm}
                disabled={!confirmButtonActive}
              >
                {confirmText}
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
