import { useMutation } from "react-query";
import axios from "axios";
import { getAPIURL } from "../core/api";
import { CheckboxItem } from "../types/form";

export default function useAccounts() {
  return useMutation((account: string) => fetch(account));
}

/**
 * @api {post} /accounts Accounts
 * @apiGroup Assets Under Management
 *
 * @apiParam {String} token
 *
 * @apiSuccessExample {json} Success-Response:
 *  HTTP/1.1 200 OK
 *  {
 *     id: string;
 *     customer: string;
 *     number: string;
 *     title: string;
 *  }
 *  @apiSampleRequest off
 */

const fetch = async (account: string): Promise<CheckboxItem[]> => {
  const response = await axios.post(getAPIURL("/accounts"), { account });
  return response.data;
};
