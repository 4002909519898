import styled from "styled-components";
import { Checkbox } from "@chakra-ui/react";
import { theme } from "../../theme";

export const SidebarCheckbox = styled(Checkbox)`
  align-items: self-start;

  .chakra-checkbox__control {
    margin-right: 6px;
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.white};

    &[data-checked] {
      background-color: ${theme.colors.accent_1_900};
      border-color: ${theme.colors.accent_1_900};
    }
  }
`;
