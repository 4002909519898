import { useState } from "react";
import { Privileges } from "../enums/enums";
import { GraphGroupingOption } from "../pages/global/graph/graph-grouping";

export function useGraphGroupingOptions() {
    const defaultOptions: GraphGroupingOption[] = [
        { label: "Branch", value: "branch", selected: true },
        { label: "Business Type", value: "business", selected: false },
        { label: "Security Type", value: "security", selected: false },
    ];

    const [defaultGraphGroupingOptions, setDefaultGraphGroupingOptions] = useState<GraphGroupingOption[]>(defaultOptions);

    const setGraphGroupingOptions = (newOptions: GraphGroupingOption[]) => setDefaultGraphGroupingOptions(newOptions);

    const setGraphGroupingOptionsByRol = (rol: Privileges) => {
        const isAdvisor = rol === Privileges.SALES_REPRESENTATIVE;

        if (isAdvisor) {
            setDefaultGraphGroupingOptions([
                { label: "Business Type", value: "business", selected: true },
                { label: "Security Type", value: "security", selected: false },
            ]);
        }
    }

    return { 
        defaultGraphGroupingOptions,
        setGraphGroupingOptions,
        setGraphGroupingOptionsByRol
    }
}