import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import GraphHeader from "./graph-header";
import MixOfBusinessGraph from "./graph";
import { MixOfBusiness } from "../../../types/mix-of-business";
import NoDataFound from "../../../components/graph/no-data-found";
import { hasKey } from "../../../utils/utils";
interface GraphLoaderProps {
    data: MixOfBusiness | undefined;
    status: string;
}

const GraphLoader: React.FC<GraphLoaderProps> = ( { data, status }) => {
  const keys = Object.keys(data ? data.securities : {});
  const hasSecurityData = hasKey(keys, data ? data.securities : {});
  const hasAccountsData = hasKey(keys, data ? data.accounts : {});
  return (
    <Flex layerStyle="shadowBox" h={"auto"}>
      <GraphHeader />
      <Flex justify="center" alignItems="flex-start" flex={1}>
      {
      status !== "success" ? 
        <Spinner size="xl" color="accent_1_900" />
      : status === "success" && !hasSecurityData   && !hasAccountsData ? 
        <NoDataFound /> :
        <>
          <Flex justify="flex-start" alignItems="center" flex={1}>
           <MixOfBusinessGraph data={data ? data.securities : []} type={"securities"} />
          </Flex>
          <Flex justify="center" alignItems="center" flex={1}>
            <MixOfBusinessGraph data={data ? data.accounts : []} type={"accounts"} />
          </Flex>
        </>
      }
      </Flex>
    </Flex>
  );
};

export default GraphLoader;
