import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";
import { FlexProps } from "@chakra-ui/layout/dist/flex";

interface LoaderProps {
  flexProps?: FlexProps;
}

const Loader: React.FC<LoaderProps> = ({ flexProps }) => {
  return (
    <Flex grow={1} justify="center" alignItems="center" {...flexProps}>
      <Spinner size="xl" color="accent_1_900" />
    </Flex>
  );
};

export default Loader;
