/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import LoginPage from "../pages/login";
import FiltersProvider from "./filters-provider";
import useLogin from "../hooks/useLogin";
import { useProxyBackend } from "../core/env";

export function BackendLoginProvider() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState("");
  const [haveToken, setHaveToken] = useState(false);
  const { status, refetch } = useLogin(accessToken);

  useEffect(() => {
    async function fetchBackendToken() {
      if (account && !haveToken) {
        if (useProxyBackend) {
          setAccessToken("token");
        } else {
          const response = await instance.acquireTokenSilent({
            scopes: ["openid"],
            account: account,
          });
          setAccessToken(response.accessToken);
        }
      }
    }

    fetchBackendToken().then();
  }, [account, haveToken]);

  useEffect(() => {
    if (accessToken) {
      refetch().then();
    }
  }, [accessToken]);

  useEffect(() => {
    if (status === "success") {
      setHaveToken(true);
    }
  }, [status]);

  return haveToken ? <FiltersProvider /> : <LoginPage />;
}

export default BackendLoginProvider;
