import React, { useState } from "react";
import { Flex, Input } from "@chakra-ui/react";
import moment from "moment";
import { theme } from "../../theme";
import { calendarFormat } from "../../core/dates";
import styled from "styled-components";

interface DateInputProps {
  startDate: Date;
  endDate: Date;
  setGlobalFilters: Function;
  setStartDate: Function;
  setEndDate: Function;
  setStartDateInput: Function;
  setEndDateInput: Function;
  startDateInput: string | null;
  endDateInput: string | null;
}

const getDate = (date: string) => date === "Invalid date" ? " " : date;

const DateInput: React.FC<DateInputProps> = ({ 
  startDate, 
  endDate, 
  setGlobalFilters, 
  setStartDate, 
  setEndDate, 
  setStartDateInput, 
  setEndDateInput,
  startDateInput,
  endDateInput
}) => {
  const start = moment(startDate).format(calendarFormat);
  const end = moment(endDate).format(calendarFormat);
  const [errorStartDate, setErrorStartDate] = useState<boolean>(false);
  const [errorEndDate, setErrorEndDate] = useState<boolean>(false);
  
  const validateStartDate = (startDate: string) => {
      const isValid = moment(startDate).isValid();
      const momentStart = moment(startDate).toDate();
      if (isValid) {
        setErrorStartDate(false);
        setGlobalFilters(momentStart, endDate);
        setStartDateInput(startDate);
        setStartDate(momentStart);
      } else {
        setErrorStartDate(true);
      }
  }

  const validateEndDate = (endDate: string) => {
    const isValid = moment(endDate).isValid();
    const momentEnd = moment(endDate).toDate();
    if (isValid) {
      setErrorEndDate(false);
      setEndDateInput(endDate);
      setGlobalFilters(startDate, momentEnd);
      setEndDate(momentEnd);
    } else {
      setErrorEndDate(true);
    }
  }
  
  return (
    <Flex direction="row" justify="space-between" mb={3}>
      <DatesInput isInvalid={errorStartDate} errorBorderColor="red.500" value={getDate(startDateInput ? startDateInput : start)} onChange={(element: React.ChangeEvent<HTMLInputElement>) => {validateStartDate(element.target.value)}} />
      <DatesInput isInvalid={errorEndDate} errorBorderColor="red.500" value={getDate(endDateInput ? endDateInput : end)} onChange={(element: React.ChangeEvent<HTMLInputElement>) => {validateEndDate(element.target.value)}} />
    </Flex>
  );
};

const DatesInput = styled(Input)`
  padding: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  font-weight: ${theme.fontWeights.bold};
  text-align: center;
  width: 72px !important;
  height: 22px !important;
  color: ${theme.colors.white};
  line-height: 10px;
  box-shadow: unset !important;
`;

export default DateInput;
