import * as React from "react";
import Providers from "./components/providers";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { Configuration, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import LoginPage from "./pages/login";
import { BackendLoginProvider } from "./components/backend-login-provider";
import { useProxyBackend } from "./core/env";
import "./servers/local-environment";
import FiltersProvider from "./components/filters-provider";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

const AzureActiveDirectoryAppClientId: any = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;
const configuration: Configuration = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    authority: "https://login.microsoftonline.com/0e7eb6e3-c263-489e-9e70-5a091c7562c6",
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};

const pca = new PublicClientApplication(configuration);

let authRequest: any = {
  scopes: ["openid", "profile"],
};

if (useProxyBackend) {
  authRequest.prompt = "select_account";
}

function ErrorComponent({ error }: { error: any }) {
  return <p>An Error Occurred: {error}</p>;
}

export const App = () => {
  return useProxyBackend ? (
    <Providers>
      <FiltersProvider />
    </Providers>
  ) : (
    <Providers>
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Popup}
          authenticationRequest={authRequest}
          //errorComponent={ErrorComponent}
          loadingComponent={LoginPage}
        >
          <BackendLoginProvider />
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </Providers>
  );
};
