import React from 'react';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { GradientPinkBlue } from '@visx/gradient';
import { LegendOrdinal } from '@visx/legend';
import { Flex, Text } from "@chakra-ui/react";
import { useTooltip } from '@visx/tooltip';
import styled from "styled-components";
import GraphTooltip from "./graph-tooltip";
import { getColorForSecurities, getColorForAccounts } from "../../../core/color";
import { useMutateFilters } from "../../../hooks/useMutateFilters";
import { FiltersType } from "../../../enums/enums";
import { filtersAtom } from "../../../states/filters";
import { useAtom } from "jotai";
interface GraphProps {
    data: any;
    type: string;
}

export interface DataSchema {
    label: string;
    total: number;
    percentage: number;
}

const width = 200;
const height = 200;
const margin = { top: 20, right: 20, bottom: 20, left: 20 };
const innerWidth = width - margin.left - margin.right;
const innerHeight = height - margin.top - margin.bottom;
const radius = Math.min(innerWidth, innerHeight) / 2;
const centerY = innerHeight / 2;
const centerX = innerWidth / 2;
const donutThickness = 30;


const MixOfBusinessGraph: React.FC<GraphProps> = ({data, type}) => {
    const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop, tooltipOpen } = useTooltip({
        tooltipOpen: false,
        tooltipData: {} as DataSchema,
    });

    const [filters] = useAtom(filtersAtom);

    const { onClickChartOption } = useMutateFilters();

    if (width < 10) return null;
    const names = Object.keys(data);

    const sum = (number: number) => {
        const d = Object.values(data);
        const reducer = d.reduce<number>((acc: any, next: any) => {
            return acc + next;
        }, 0);

        return parseFloat(`${(number * 100) / reducer}`).toFixed(2);
    }
    
    const dataSchema = names.map((name) => ({
        label: name,
        total: Number(data[`${name}`]),
        percentage: parseInt(sum(data[`${name}`])),
    }));
    
    const total = (d: DataSchema) => d.total;

    const getSecurityColors = scaleOrdinal({
        domain: dataSchema.map((s) => s.label).sort((a, b) => a.localeCompare(b)),
        range: type === "securities" ? dataSchema.sort((a, b) => a.label.localeCompare(b.label)).map((s) => getColorForSecurities(s.label, dataSchema.indexOf(s))) : dataSchema.sort((a, b) => a.label.localeCompare(b.label)).map((s) => getColorForAccounts(s.label, dataSchema.indexOf(s))),
    });

    const handleMouseOver = (event: any, datum: {label: string, total: number, percentage: number}) => {
        showTooltip({
            tooltipLeft: event.clientX,
            tooltipTop: event.pageY - 75,
            tooltipData: datum
        });
    };

    const onClickPiePortion = (label: string) => {
        let type: FiltersType = FiltersType.SECURITIES;

        switch (label) {
            case 'Brokerage': type = FiltersType.BUSINESSES;
            break;
            case 'Fee Based': type = FiltersType.BUSINESSES;
            break;
            default: type = FiltersType.SECURITIES;
        }
        
        onClickChartOption(filters, type, label);
    }

    return (
        <Flex>
            <Flex flexDirection="column">
                <Text textStyle="figureTitle" color="#979797">{type === "securities" ? "Securities" : "Accounts"}</Text>
                <svg width={width} height={height}>
                    <GradientPinkBlue id="visx-pie-gradient" />
                    <Group top={centerY + margin.top} left={centerX + margin.left}>
                        <Pie
                            data={dataSchema}
                            pieValue={total}
                            outerRadius={radius}
                            innerRadius={radius - donutThickness}
                            cornerRadius={1}
                            padAngle={0.015}
                        >
                            {pie => {
                                return pie.arcs.map((arc, index) => {
                                    const { label } = arc.data;
                                    const arcPath = pie.path(arc)!;
                                    const arcFill = getSecurityColors(label);
                                    return (
                                    <g
                                    onClick={() => onClickPiePortion(dataSchema[index].label)}
                                    onMouseOver={(e) => {
                                        const tooltipData =  dataSchema[index];
                                        handleMouseOver(e, tooltipData);
                                    }}
                                    onMouseOut={hideTooltip}
                                    key={`arc-${label}-${index}`}>
                                        <path d={arcPath} fill={arcFill} />
                                    </g>
                                    );
                                })
                            }}
                        </Pie>
                    </Group>
                </svg>
                {tooltipOpen && (
                    <GraphTooltip top={tooltipTop} left={tooltipLeft} data={tooltipData} />
                )}
            </Flex>
            <CustomLegend shapeWidth={30} scale={getSecurityColors} labelFormat={label => label} direction="column" labelMargin="0 15px 0 0" /> 
        </Flex>
    );
}

const CustomLegend = styled(LegendOrdinal)`
    .visx-legend-shape {
        div {
            border-radius: 2px;
            margin-right: 0.9em;
        }
    }
`

export default MixOfBusinessGraph;