import { theme, WINDOW_WIDTH_1366, WINDOW_WIDTH_1600 } from "../../../theme";
import { max } from "d3-array";
import { formatNumber } from "../../../utils/utils";
import { WindowSize } from "../../../states/dimensions";

export const GraphAreaHeight = 650;

export const SeparatorConstants = {
  graphSeparator: {
    x: 10,
    yModifier: 12,
    widthModifier: 26,
  },
  graphSeparatorWidth: 1000,
  axisSeparatorWidth: 908,
  margin: {
    left: 89,
  },
};

export const getAUMGraphConstants = (windowSize: WindowSize) => {
  let graphWidth = 750; // small desktop

  if (windowSize.width >= WINDOW_WIDTH_1366) {
    graphWidth = 760;
  }

  if (windowSize.width >= WINDOW_WIDTH_1600) {
    graphWidth = 980;
  }

  const graphConstants = {
    width: graphWidth,
    height: 320,
    margin: {
      top: 0,
      right: 0,
      bottom: 20,
      left: 20,
      yAxisMargin: 100,
      xAxisMargin: 130,
      tooltip: { left: 50, top: -80 },
    },
    axis: {
      left: 52,
      top: 6,
    },
  };

  return {
    ...graphConstants,
    yMax: graphConstants.height - graphConstants.margin.top - graphConstants.margin.bottom,
    xMax: graphConstants.width - graphConstants.margin.left - graphConstants.margin.right,
  };
};

export const getCashFlowGraphConstants = () => {
  const graphConstants = {
    width: 1000,
    height: 240,
    margin: {
      top: 10,
      right: 0,
      bottom: 20,
      left: 20,
      yAxisMargin: 100,
    },
  };

  return {
    ...graphConstants,
    yMax: graphConstants.height - graphConstants.margin.top - graphConstants.margin.bottom,
    xMax: graphConstants.width - graphConstants.margin.left - graphConstants.margin.right,
  };
};

export const getAccessors = () => {
  return {
    getIndex: (d: any, data: any) => data.findIndex((x: any) => x.date === d.date),
    formatY: (value: any) => {
      const label = value >= 0 ? formatNumber(value, "en-US") : formatNumber(value * -1, "en-US");
      return value >= 0 ? `$${label}` : `($${label})`;
    },
  };
};

export const tooltipInitialState = {
  tooltipOpen: false,
  tooltipLeft: 0,
  tooltipTop: 0,
  tooltipData: [],
};

export const tickLabelProps = () =>
  ({
    fontSize: 12,
    fontFamily: theme.fonts.heading,
    textAnchor: "middle",
    fill: theme.colors.grey_50,
  } as const);

export const getMaxAreaValue = (data: any, keys: any) => {
  const totals = data.map((item: any) => {
    let total: number = 0;
    keys.forEach((key: string) => {
      if (parseFloat(item[key]) >= 1) {
        total += parseFloat(item[key]);
      }
    });

    return total;
  });

  return Number(max(totals));
};

export const getCashFlowRanges = (movementsData: any) => {
  const keys = ["deposit", "acatIn", "withdrawal", "acatOut"];
  const totals: number[] = [];
  movementsData.forEach((movement: any) => {
    keys.forEach((key) => {
      if (key === "withdrawal") movement["withdrawal"] = -Math.abs(movement["withdrawal"]);
      totals.push(movement[key]);
    });
  });
  const max = Math.max(...totals);
  const min = Math.min(...totals);

  return { max, min };
};

export const getY0 = (d: any) => d[0];
export const getY1 = (d: any) => d[1];

export const getXAdjust = (total: number, current: number) => {
  let xAdjust = 0;
  if (current === 0) {
    xAdjust = 6;
  }
  if (current === total) {
    xAdjust = -6;
  }

  return xAdjust;
};
