import React from "react";
import { Orientation, Axis, AxisScale, AxisScaleOutput } from "@visx/axis";
import { formatDates } from "../../../utils/utils";
interface GraphAxisProps {
  branchesScale: AxisScale<AxisScaleOutput>;
  dateScale: AxisScale<AxisScaleOutput>;
  yMax: number;
  formatX: Function;
  formatY: Function;
}

const tickLabelProps = () =>
  ({
    fontSize: 12,
    fontFamily: "Roboto",
    textAnchor: "middle",
    fill: "#979797",
  } as const);

const GraphAxis: React.FC<GraphAxisProps> = ({ branchesScale, dateScale, yMax, formatX, formatY }) => {
  return (
    <>
      <Axis
        orientation={Orientation.left}
        scale={branchesScale}
        hideTicks
        hideAxisLine
        left={50}
        tickLabelProps={tickLabelProps}
        tickFormat={(v) => formatY(v)}
      />
      <Axis
        orientation={Orientation.bottom}
        scale={dateScale}
        top={yMax}
        stroke="#E4E3E9"
        hideTicks
        tickLabelProps={tickLabelProps}
        tickFormat={(v) => formatDates(v)}
      />
    </>
  );
};

export default GraphAxis;
