/* eslint-disable */
import React, { ChangeEvent, useEffect, useState } from "react";
import { Text, VStack, Box } from "@chakra-ui/react";
import { SidebarCheckbox } from "./sidebar-checkbox";
import { CheckboxItem, CheckboxSalesRepChildren } from "../../types/form";
import { filtersAtom } from "../../states/filters";
import { useAtom } from "jotai";
import Accordion from "./accordion";
import { FiltersName, FiltersType } from "../../enums/enums";
import AutoCompleteField from "./auto-complete";
import styled from "styled-components";

interface CheckboxAccordionProps {
  name: string;
  checkboxes: CheckboxItem[];
  setCheckboxes: Function;
  filtersID: string;
  allCheckboxName: string;
  isOpen: boolean;
}

const CheckboxGroup: React.FC<CheckboxAccordionProps> = ({
  checkboxes,
  name,
  filtersID,
  allCheckboxName,
  setCheckboxes,
  isOpen,
}) => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [allSelected, setAllSelected] = useState(true);
  const [children, setChildren] = useState<CheckboxSalesRepChildren[]>([]);
  const checkedFilters = checkboxes.filter((checkbox: CheckboxItem) => checkbox.checked);
  const checkedChildren = children.filter((child) => child.checked);

  useEffect(() => {
    const nonSelectedCheckboxes = !checkboxes.some((checkbox) => checkbox.checked);
    const nonSelectedChildren = !children.some((checkbox) => checkbox.checked);
    setAllSelected(nonSelectedCheckboxes && nonSelectedChildren);
  }, [checkboxes, children]);

  useEffect(() => {
    let children: CheckboxSalesRepChildren[] = [];
    for (let item of checkboxes) {
      if (item.children?.length) {
        for (let child of item.children) {
          children.push(child);
        }
      }
    }

    setChildren(children);
  }, [checkboxes]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const nextCheckboxes = checkboxes.map((checkbox) => {
      const ckbx = {
        ...checkbox,
        checked: String(checkbox.value) === e.target.value ? !checkbox.checked : checkbox.checked,
        children: checkbox.children?.map((cbx) => {
          return {
            ...cbx,
            checked: String(cbx.id) === e.target.value ? !cbx.checked : cbx.checked,
          };
        }),
      };

      const hasChildren = ckbx.children && ckbx.children.length;
      const hasChildrenSelected = ckbx.children?.some((child) => child.checked);

      if (checkbox.checked && hasChildren && !hasChildrenSelected) return { ...ckbx, checked: false };

      return {
        ...ckbx,
        children: ckbx.children?.map((child) => {
          return {
            ...child,
            checked:
              String(ckbx.value) === e.target.value && ckbx.checked
                ? true
                : String(child.id) === e.target.value && child.checked
                ? true
                : !ckbx.checked && child.checked && String(ckbx.value) === e.target.value
                ? false
                : String(child.id) !== e.target.value && child.checked
                ? true
                : child.checked,
          };
        }),
      };
    });

    updateState(nextCheckboxes);
  };

  const onChangeAll = () => {
    const nextCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: false,
      children: checkbox.children?.map((child) => {
        return { ...child, checked: false };
      }),
    }));
    updateState(nextCheckboxes);
  };

  const updateState = (checkboxes: CheckboxItem[]) => {
    if (!checkboxes.length) return;

    setCheckboxes(checkboxes);
    setFilters({ ...filters, [filtersID]: checkboxes });
  };

  const hasSelected = checkboxes.some((checkbox) => checkbox.checked);

  const displayAutoComplete = (filterLabels: FiltersName[]) => {
    const foundIndex = filterLabels.map((label) => label).indexOf(name as FiltersName);
    const types = filterLabels.map((label) => {
      let type;
      switch (label) {
        case FiltersName.BRANCH_OFFICE:
          type = FiltersType.BRANCHES;
          break;
        case FiltersName.ACCOUNTS:
          type = FiltersType.ACCOUNTS;
          break;
        case FiltersName.SALES_REP:
          type = FiltersType.REPS;
          break;
        case FiltersName.BUSINESS_TYPE:
          type = FiltersType.BUSINESSES;
          break;
        case FiltersName.SECURITY_TYPE:
          type = FiltersType.SECURITIES;
          break;
      }

      return type;
    });

    if (foundIndex > -1) {
      return <AutoCompleteField label={filterLabels[foundIndex]} type={types[foundIndex]} />;
    }
    return null;
  };

  return (
    <>
      <Accordion name={name} highlight={hasSelected}>
        <VStack spacing={2} align="start" width={180}>
          {displayAutoComplete([FiltersName.SALES_REP, FiltersName.ACCOUNTS])}
          <SidebarCheckbox isChecked={allSelected} value={"all"} onChange={onChangeAll} iconColor="primary900">
            <Text textStyle="sortCategory" color={allSelected ? "accent_1_900" : "white"} textTransform="capitalize">
              {allCheckboxName}
            </Text>
          </SidebarCheckbox>
          {checkboxes.map((checkbox: CheckboxItem, index: number) => (
            <Box key={`${checkbox.name}-${index}`}>
              <SidebarCheckbox
                isChecked={checkbox.checked}
                value={checkbox.value}
                onChange={onChange}
                iconColor="primary900"
              >
                <Text
                  textStyle="sortCategory"
                  color={checkbox.checked ? "accent_1_900" : "white"}
                  textTransform="capitalize"
                >
                  {checkbox.name}
                </Text>
              </SidebarCheckbox>
              {checkbox.children?.length ? (
                <Box background="#2f2f2f" padding="10px" width="100%">
                  {checkbox.children?.map((child, index) => (
                    <SidebarCheckbox
                      key={`${checkbox.name}-${child.value}-${index}`}
                      isChecked={child.checked}
                      value={child.id}
                      onChange={onChange}
                      iconColor="primary900"
                    >
                      <Text
                        textStyle="sortCategory"
                        color={child.checked ? "accent_1_900" : "white"}
                        textTransform="capitalize"
                      >
                        {`${child.value} ${child.name}`}
                      </Text>
                    </SidebarCheckbox>
                  ))}
                </Box>
              ) : null}
            </Box>
          ))}
        </VStack>
      </Accordion>
      {!isOpen &&
        checkedFilters &&
        checkedFilters.map((checkbox: CheckboxItem, index: number) => (
          <CheckboxesContainer
            key={index}
            isChecked={checkbox.checked}
            value={checkbox.value}
            onChange={onChange}
            iconColor="primary900"
          >
            <Text
              textStyle="sortCategory"
              color={checkbox.checked ? "accent_1_900" : "white"}
              textTransform="capitalize"
            >
              {checkbox.name}
            </Text>
          </CheckboxesContainer>
        ))}

      {!isOpen &&
        checkedChildren &&
        checkedChildren.map((child, index: number) => (
          <CheckboxesContainer
            key={`${child.value}-${index}`}
            isChecked={child.value === child.id}
            value={child.id}
            onChange={onChange}
            iconColor="primary900"
          >
            <Text textStyle="sortCategory" color={child.checked ? "accent_1_900" : "white"} textTransform="capitalize">
              {`${child.value} ${child.name}`}
            </Text>
          </CheckboxesContainer>
        ))}
    </>
  );
};

const CheckboxesContainer = styled(SidebarCheckbox)`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export default CheckboxGroup;
