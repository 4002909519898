import { useMutation } from "react-query";
import axios from "axios";
import { getAPIURL } from "../../core/api";

export default function useGraphTable() {
  return useMutation((date: string) => fetch(date));
}

/**
 * @api {post} /production-dashboard/graph/table Table
 * @apiGroup Production Dashboard
 * @apiSampleRequest off
 *
 * @apiParam {Object} filters
 * @apiParam {Date} filters.startDate
 * @apiParam {Date} filters.endDate
 * @apiParam {String[]} filters.branches="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.reps="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.businesses="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.securities="IDs to select. If empty, all are used."
 * @apiParam {String="branch", "business", "security"} grouping
 *
 * @apiSuccessExample {json} Success-Response:
 * HTTP/1.1 200 OK
 * [
 *    {
 *          "trade_date": Date, // (ISO 8601 format)
 *          "settlement_date": Date, // (ISO 8601 format)
 *          "account_number": string,
 *          "transaction_type": string,
 *          "security": string,
 *          "amount": number
 *      }
 * ]
 */
const fetch = async (selectedDate: string) => {
  const response = await axios.post(getAPIURL("/production-dashboard/graph/table"), {
    selectedDate,
  });

  return response.data;
};
