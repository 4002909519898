import React, { ReactNode } from "react";
import { Flex, Text } from "@chakra-ui/react";

interface SectionTitleProps {
  label: string;
  rightControls?: ReactNode;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ label, rightControls }) => {
  return (
    <Flex layerStyle="sectionBox">
      <Text textStyle="sectionTitle">{label}</Text>
    </Flex>
  );
};

export default SectionTitle;
