import React from "react";
import { Flex, Grid, GridItem, HStack, Text } from "@chakra-ui/react";
import SectionTitle from "../../components/section-title";
import SummaryStack from "../../components/summary-stack";
import moment from "moment";
import useSummary from "../../hooks/assets-under-management/useSummary";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";
import Loader from "../../components/loader";
import { formatNumber } from "../../utils/utils";

interface SummaryProps {}

const Summary: React.FC<SummaryProps> = () => {
  const [filters] = useAtom(filtersAtom);
  const { status, data } = useSummary(filters);
  const yesterdayLabel = moment(filters.endDate).format("l");

  return (
    <Flex layerStyle="shadowBox">
      <SectionTitle label={`As of ${yesterdayLabel}`} />
      <Flex justifyContent="space-between" h="70px">
        {status !== "success" ? (
          <Loader />
        ) : (
          data && (
            <>
              <Grid pb={8} templateColumns="repeat(6, 1fr)" gap={6}>
                <GridItem w="100%">
                  <HStack w="full">
                    <SummaryStack>
                      <Text textStyle="figureTitle">TOTAL AUM</Text>
                      <HStack w="full" justify="space-between" align="baseline">
                        <Text textStyle="aumTotal">${formatNumber(data?.totalAUM, "en-US")}</Text>
                      </HStack>
                    </SummaryStack>
                  </HStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle" mb={5}></Text>
                    <Text textStyle="bodyBold">YTD</Text>
                    <Text textStyle="bodyBold">MTD</Text>
                  </SummaryStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle">CHANGE</Text>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.change.YTD.value, "en-US")}</Text>
                      <Text textStyle="bodyRegular">{data?.change.YTD.change.toFixed(1)}%</Text>
                    </HStack>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.change.MTD.value, "en-US")}</Text>
                      <Text textStyle="bodyRegular">{data?.change.MTD.change.toFixed(1)}%</Text>
                    </HStack>
                  </SummaryStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle">NET NEW ASSETS</Text>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.netNewAssets.YTD.value, "en-US")}</Text>
                      <Text textStyle="bodyRegular">{data?.netNewAssets.YTD.change.toFixed(1)}%</Text>
                    </HStack>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.netNewAssets.MTD.value, "en-US")}</Text>
                      <Text textStyle="bodyRegular">{data?.netNewAssets.MTD.change.toFixed(1)}%</Text>
                    </HStack>
                  </SummaryStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle">NEW ACCOUNTS</Text>
                    <Text textStyle="bodyBold">{formatNumber(data?.newAccounts.YTD, "en-US")}</Text>
                    <Text textStyle="bodyBold">{formatNumber(data?.newAccounts.MTD, "en-US")}</Text>
                  </SummaryStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle">NEW HOUSEHOLDS</Text>
                    <Text textStyle="bodyBold">{formatNumber(data?.newHouseholds.YTD, "en-US")}</Text>
                    <Text textStyle="bodyBold">{formatNumber(data?.newHouseholds.MTD, "en-US")}</Text>
                  </SummaryStack>
                </GridItem>
              </Grid>
            </>
          )
        )}
      </Flex>
    </Flex>
  );
};

export default Summary;
