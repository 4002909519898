import React from "react";
import usePDTopAccounts from "../../hooks/production-dashboard/usePDTopAccounts";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";
import TopAccounts from "../global/top-accounts";

const PDTopAccounts = () => {
  const [filters] = useAtom(filtersAtom);
  const { status, data } = usePDTopAccounts(filters);
  const sectionTitle = "Top PD Accounts";

  return <TopAccounts data={data} status={status} sectionTitle={sectionTitle} />;
};

export default PDTopAccounts;
