import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { capitalizeFirstLetter } from "../../../core/strings";
import { getFinancialNumber } from "../../../core/numbers";

interface GraphToolTipContentProps {
  entry: any;
  index: number;
}

const GraphToolTipContent: React.FC<GraphToolTipContentProps> = ({ entry, index }) => {
  return entry[0] === "date" ? (
    <Box key={index} />
  ) : (
    <Box key={index}>
      {entry[0] === "deposit" && <Text mb={3} />}
      <Flex direction="row" justifyContent="space-between">
        <Text fontSize="xs" color="white" mb={1} marginRight={10}>
          {`${capitalizeFirstLetter(entry[0])}`}
        </Text>
        <Text fontSize="xs" color="white" mb={1}>
          {getFinancialNumber(entry[1])}
        </Text>
      </Flex>
    </Box>
  );
};

export default GraphToolTipContent;
