import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { capitalizeFirstLetter } from "../../../core/strings";
import { formatNumber } from "../../../utils/utils";

interface TooltipContentProps {
    type: any;
    value: any;
}

const GraphTooltipContent: React.FC<TooltipContentProps> = ({ type, value }) => {
    return (
        <>
            {type === "deposit" && <Text mb={3} />}
            <Flex direction="row">
                {type && value !== null && (
                    <>
                        <Text fontSize="xs" color="white" mb={1} w={86}>
                        {`${capitalizeFirstLetter(type ? type : "")}`}
                        </Text>
                        <Text fontSize="xs" color="white" mb={1}>
                        {`$${formatNumber(value, "en-US")}`}
                        </Text>
                    </>
                )}
            </Flex>
        </>
    );
}

export default GraphTooltipContent;