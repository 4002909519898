import React, { useState, useEffect } from "react";
import { Text, Box, Input, List, ListItem } from "@chakra-ui/react";
import { FiltersName, FiltersType } from "../../enums/enums";
import styled from "styled-components";
import { useMutateFilters } from "../../hooks/useMutateFilters";
import { filtersAtom } from "../../states/filters";
import useSearchTerm from "../../hooks/useSearchTerm";
import { useDebounce } from "../../hooks/useDebounce";
import { useAtom } from "jotai";

interface AutoCompleteFieldProps {
  label: FiltersName;
  type: FiltersType;
}

const AutoCompleteField: React.FC<AutoCompleteFieldProps> = ({ label, type }) => {
  const [filters] = useAtom(filtersAtom);
  const { onSelectByTerm } = useMutateFilters();
  const [isTyping, setIsTyping] = useState(false);
  const labelInfo = type.slice(0, -1);
  const [terms, setTerms] = useState<string | undefined>(undefined);
  const debouncedSearchTerms = useDebounce(terms, 800);
  const { onSearchTerm, options } = useSearchTerm();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  useEffect(() => {
    onSearchTerm(type, debouncedSearchTerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerms, terms]);

  useEffect(() => {
    setShowOptions(!!options.length);
  }, [options]);

  return (
    <Container>
      <Box>
        <Box marginTop={5} width="100%" position="relative">
          <Input
            width="180px"
            variant="flushed"
            placeholder={`Search ${label.toLowerCase()}`}
            size="xs"
            color="white"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setIsTyping(!!e.target.value);
              setTerms(e.target.value);
              setInputValue(e.target.value);
            }}
            value={inputValue}
          />
          {!isTyping && <Label>{`Type ${labelInfo} name for additional results`}</Label>}
          {showOptions && isTyping ? (
            <Box position="absolute" top="30px" background="#aec2da" width="180px" zIndex={10} padding="10px 0">
              <List>
                {options.map((opt) => (
                  <ListItem
                    onClick={() => {
                      onSelectByTerm(filters, type, options, opt.name);
                      setIsTyping(false);
                      setShowOptions(!showOptions);
                      setTerms(undefined);
                      setInputValue("");
                    }}
                    cursor="pointer"
                    fontSize={14}
                    key={opt.value}
                    padding="5px 0px"
                    color="#000"
                    margin="0 5px"
                    borderBottom="2px solid #fff"
                    _last={{ borderBottom: 0 }}
                  >
                    {`${opt.name}`}
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Container>
  );
};

const Container = styled.div`
    display: flex;
    flex-direction: column:
    align-self: flex-start;
    position: sticky;
    top: -10px;
    margin-top: -10px;
    width: 100%;
    background: #42474a;
    z-index: 1;
  
`;

const Label = styled(Text)`
  font-size: 12px;
  font-style: italic;
  color: #8f9bab;
  margin-top: 5px;
`;

export default AutoCompleteField;
