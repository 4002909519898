import { useQuery } from "react-query";
import axios from "axios";
import { getAPIURL } from "../core/api";
import { User } from "../types/user";

export default function useCurrent() {
  return useQuery(["current"], () => fetch());
}

/**
 * @api {post} /current User
 * @apiGroup Assets Under Management
 *
 * @apiParam {String} token
 *
 * @apiSuccessExample {json} Success-Response:
 *  HTTP/1.1 200 OK
 *  {
 *     id: number;
 *     first_name: string;
 *     last_name: string;
 *     email: string;
 *     representatives: [BranchOffice: "Fairfield, CT", Description: "Unassigned", SalesRepID: "0"];
 *     branch_offices: ["Fairfield, CT"];
 *     role_name: string;
 *  }
 *  @apiSampleRequest off
 */
const fetch = async (): Promise<User> => {
  const response = await axios.get(getAPIURL("/current"), {});

  return response.data;
};
