import React, { useState } from "react";
import { Flex, Text, Box, Menu, MenuButton, MenuList, Textarea } from "@chakra-ui/react";
import { theme } from "../../theme";
import styled from "styled-components";
import { ReactComponent as Print } from "../../assets/images/print.svg";
import { ReactComponent as User } from "../../assets/images/user.svg";
import ConfirmationModal from "../confirmation-modal";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";
import { deCamelCase } from "../../core/strings";
import { userAtom } from "../../states/user";
import axios from "axios";
import { getAPIURL } from "../../core/api";
import { ImpersonateUserIDKey, ImpersonateUserLabelKey } from "../../core/impersonate";
import ImpersonateUser from "./impersonate-user";
import { useMsal } from "@azure/msal-react";

const RightControls = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [reportSent, setReportSent] = useState(false);
  const [reportMessage, setReportMessage] = useState("");
  const [sendingReport, setSendingReport] = useState(false);
  const [filters] = useAtom(filtersAtom);
  const [user] = useAtom(userAtom);
  const impersonatedUserID = localStorage.getItem(ImpersonateUserIDKey);
  const impersonatedUserLabel = localStorage.getItem(ImpersonateUserLabelKey);
  const { instance } = useMsal();

  function getFiltersForReport() {
    let filtersJSON: any = [];

    Object.entries(filters).forEach((filter) => {
      let value;
      if (filter[1] instanceof Date) {
        value = filter[1].toISOString();
      }

      if (filter[1] instanceof Array) {
        const checked = filter[1].filter((option) => option.checked);

        if (checked.length === 0) {
          return;
        }

        value = checked.map((option) => option.value);
      }

      filtersJSON.push({ name: deCamelCase(filter[0]), value });
    });

    return filtersJSON;
  }

  const onModalClose = () => {
    setModalOpen(false);
    setReportMessage("");
    setReportSent(false);
    setSendingReport(false);
  };

  const onConfirmReport = async () => {
    setSendingReport(true);

    await axios.post(getAPIURL("/reports/issue"), {
      issue: reportMessage,
      page: window.location.href,
      user: JSON.stringify(user, null, 4),
      filters: JSON.stringify(getFiltersForReport(), null, 4),
    });

    setReportSent(true);
  };

  const controls = [
    {
      label: "Print",
      icon: <Print />,
      action: () => window.print(),
    },
    {
      label: "Report an Issue",
      icon: null,
      action: () => setModalOpen(true),
    },
  ];

  return (
    <Flex flexDir="row" alignItems="flex-end">
      {controls.map((control, index) => (
        <Flex onClick={control.action} key={`${control.label}-${index}`}>
          <Box sx={{ marginRight: "7px", cursor: "pointer" }}>{control.icon}</Box>
          <Label sx={theme.textStyles.bodySmallLink}>{control.label}</Label>
        </Flex>
      ))}
      <Menu>
        <MenuButton>
          <User fill={impersonatedUserID ? theme.colors.accent_1_900 : theme.colors.secondary900} />
        </MenuButton>
        <CustomMenuList>
          <CustomMenuOption sx={theme.textStyles.bodyRegular}>
            <ImpersonateUser impersonatedUserID={impersonatedUserID} impersonatedUserLabel={impersonatedUserLabel} />
          </CustomMenuOption>
          {!impersonatedUserID && (
            <CustomMenuOption
              sx={theme.textStyles.bodyRegular}
              onClick={() => instance.logout()}
              _hover={{
                background: theme.colors.grey_3_50,
              }}
              cursor="pointer"
            >
              Log out
            </CustomMenuOption>
          )}
        </CustomMenuList>
      </Menu>
      <ConfirmationModal
        isOpen={modalOpen}
        onConfirm={onConfirmReport}
        onClose={onModalClose}
        title="Report an issue"
        showButtons={!reportSent}
        confirmText="Send report"
        confirmButtonActive={!sendingReport}
      >
        <Box mx={6}>
          {reportSent ? (
            <Text mb={2}>Thank you!</Text>
          ) : (
            <>
              <Text mb={3}>Please describe the issue here:</Text>
              <Textarea
                placeholder="Description of the issue"
                value={reportMessage}
                onChange={(e) => setReportMessage(e.target.value)}
              />
              <Text fontSize="sm" mb={3}>
                An email will be sent to our Helpdesk team and we will be in touch shortly.
              </Text>
            </>
          )}
        </Box>
      </ConfirmationModal>
    </Flex>
  );
};

const Label = styled(Text)`
  cursor: pointer;
  margin-right: 33px;
`;

const CustomMenuList = styled(MenuList)`
  border: 0;
  border-radius: 0;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.06);
`;

const CustomMenuOption = styled(Flex)`
  padding: 6px 12px;
`;

export default RightControls;
