import { useQuery } from "react-query";
import axios from "axios";
import { Filters } from "../../states/filters";
import { TopAccounts } from "../../types/top-accounts";
import { getAPIURL } from "../../core/api";
import { getFiltersForBackend } from "../../core/filters";

export default function useAUMTopAccounts(filters: Filters) {
  return useQuery(["assets-under-management-top-accounts", filters], () => fetch(filters));
}

/**
 * @api {post} /assets-under-management/top-accounts Top Accounts
 * @apiGroup Assets Under Management
 * @apiSampleRequest off
 *
 * @apiParam {Object} filters
 * @apiParam {Date} filters.startDate
 * @apiParam {Date} filters.endDate
 * @apiParam {String[]} filters.branches="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.reps="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.businesses="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.securities="IDs to select. If empty, all are used."
 *
 * @apiSuccessExample {json} Success-Response:
 * HTTP/1.1 200 OK
 * [
 *    {
 *      id: string;
 *      number: string;
 *      title: string;
 *      customer: string;
 *      total: number;
 *    },
 * ]
 */
const fetch = async (filters: Filters): Promise<TopAccounts[]> => {
  const newFilters = getFiltersForBackend(filters);
  const response = await axios.post(getAPIURL("/assets-under-management/top-accounts"), {
    ...newFilters,
  });

  return response.data;
};
