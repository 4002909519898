import React from "react";
import useTopPerformersGross from "../../hooks/production-dashboard/useTopPerformersGross";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";
import TopSales from "../global/top-sales";

const TopPerformersGross = () => {
  const [filters] = useAtom(filtersAtom);
  const { status, data } = useTopPerformersGross(filters);
  const sectionTitle = "Top Performers - Gross Production";

  return <TopSales data={data} status={status} sectionTitle={sectionTitle} />;
};

export default TopPerformersGross;
