import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { windowsSizeAtom } from "../states/dimensions";
import { WINDOW_WIDTH_1280, WINDOW_WIDTH_1366, WINDOW_WIDTH_1600 } from "../theme";

export function useLayoutWidth() {
  const [width, setWidth] = useState(0);
  const [windowSize] = useAtom(windowsSizeAtom);

  useEffect(() => {
    let layoutWidth = WINDOW_WIDTH_1280;

    if (windowSize.width >= WINDOW_WIDTH_1366) {
      layoutWidth = WINDOW_WIDTH_1366;
    }

    if (windowSize.width >= WINDOW_WIDTH_1600) {
      layoutWidth = WINDOW_WIDTH_1600;
    }

    setWidth(layoutWidth);
  }, [windowSize]);

  return width;
}
