import { FiltersType } from "../enums/enums";
import useAccounts from "./useAccounts";
import { createCheckboxesFromJSON } from "../core/sidebar";
import useReps from "./useReps";

export default function useSearchTerm() {
  const { mutate, data } = useAccounts();
  const { mutate: mutateReps, data: repsData } = useReps();

  const onSearchTerm = (type: FiltersType, term: string) => {
    switch (type) {
      case FiltersType.ACCOUNTS:
        mutate(term);
        break;
      case FiltersType.REPS:
        mutateReps(term);
        break;
    }
  };

  return {
    options: data ? createCheckboxesFromJSON(data) : repsData ? createCheckboxesFromJSON(repsData) : [],
    onSearchTerm,
  };
}
