/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useBackendFilters from "../hooks/useBackendFilters";
import DataPortalLayout from "./layout/data-portal-layout";
import LoginPage from "../pages/login";
import { useAtom } from "jotai";
import { filtersAtom, initialFiltersAtom } from "../states/filters";
import useCurrent from "../hooks/useCurrent";
import { privileges } from "../states/privileges";
import { MutateFiltersProvider } from "../hooks/useMutateFilters";
import { userAtom } from "../states/user";
import { Privileges } from "../enums/enums";
interface FiltersProviderProps {}

const FiltersProvider: React.FC<FiltersProviderProps> = () => {
  const { data: filters, status } = useBackendFilters();
  const [haveFilters, setHaveFilters] = useState(false);
  const [, setInitialFilters] = useAtom(initialFiltersAtom);
  const [, setFilters] = useAtom(filtersAtom);
  const [, setRol] = useAtom(privileges);
  const [, setUser] = useAtom(userAtom);
  const { data } = useCurrent();

  useEffect(() => {
    if (status === "success" && filters) {
      setInitialFilters(filters);
      setFilters(filters);
      setHaveFilters(true);
    }
  }, [status, filters]);

  useEffect(() => {
    if (data) {
      setRol(data.role_name as Privileges);
      setUser(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return haveFilters ? (
    <MutateFiltersProvider filters={filters}>
      <DataPortalLayout />
    </MutateFiltersProvider>
  ) : (
    <LoginPage />
  );
};

export default FiltersProvider;
