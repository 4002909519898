/* eslint-disable react-hooks/exhaustive-deps */
// TODO Type everything and stop using any
import React, { useCallback, useState } from "react";
import { scaleLinear } from "@visx/scale";
import { Orientation, Axis } from "@visx/axis";
import { useTooltip, Tooltip } from "@visx/tooltip";
import { tooltipStyles } from "../../../components/tooltip";
import {
  getAccessors,
  getAUMGraphConstants,
  tooltipInitialState,
  getMaxAreaValue,
  getCashFlowRanges,
  tickLabelProps,
  getCashFlowGraphConstants,
  SeparatorConstants,
} from "./graph-helper";
import GraphCashFlowBars from "./graph-cash-flow-bars";
import GraphAreas from "./graph-areas";
import GraphTransparentBackground from "../../../components/graph/transparent-background";
import GraphXAxisSeparator from "./graph-x-axis-separator";
import GraphHoverBar from "./graph-hover-bar";
import HorizontalGridRows from "../../../components/graph/horizontal-grid-rows";
import GraphToolTipContent from "./graph-tooltip-content";
import GraphLabel from "./graph-label";
import { useAtom } from "jotai";
import { windowsSizeAtom } from "../../../states/dimensions";

const CashFlowGraphConstants = getCashFlowGraphConstants();
const accessors = getAccessors();

interface GraphProps {
  data: any;
  groupingOption: string;
}

const AUMGraph: React.FC<GraphProps> = ({ data, groupingOption }) => {
  const [windowSize] = useAtom(windowsSizeAtom);
  const AUMGraphConstants = getAUMGraphConstants(windowSize);
  const [currentX, setCurrentX] = useState<number | undefined>(undefined);
  const { areasData, movementsData, keys } = data;
  const { showTooltip, hideTooltip, tooltipData, tooltipLeft = 0, tooltipTop = 0, tooltipOpen } = useTooltip(
    tooltipInitialState
  );

  const xScale = scaleLinear<number>({
    range: [AUMGraphConstants.margin.yAxisMargin, AUMGraphConstants.xMax],
    domain: [0, areasData.length - 1],
  });

  const maxAreaValue = getMaxAreaValue(areasData, keys);
  const yScale = scaleLinear<number>({
    range: [AUMGraphConstants.yMax, 0],
    domain: [0, maxAreaValue],
  });

  const cashFlowRanges = getCashFlowRanges(movementsData);
  const cashFlowScale = scaleLinear<number>({
    range: [
      AUMGraphConstants.height + AUMGraphConstants.margin.bottom + CashFlowGraphConstants.margin.top + 15,
      AUMGraphConstants.height + CashFlowGraphConstants.height,
    ],
    domain: [cashFlowRanges.max, cashFlowRanges.min],
  });

  const handleTooltip = useCallback(
    (event: any, data: any) => {
      const rect = event.currentTarget.getBoundingClientRect();

      showTooltip({
        tooltipData: Object.entries(data) as never,
        tooltipLeft: rect.x + AUMGraphConstants.margin.tooltip.left,
        tooltipTop: event.clientY + window.scrollY + AUMGraphConstants.margin.tooltip.top,
      });
    },
    [data]
  );

  return (
    <>
      <svg
        width={AUMGraphConstants.width}
        height={
          AUMGraphConstants.height +
          AUMGraphConstants.margin.bottom +
          CashFlowGraphConstants.height +
          CashFlowGraphConstants.margin.bottom
        }
      >
        <GraphTransparentBackground graphConstants={AUMGraphConstants} />
        <Axis
          orientation={Orientation.left}
          scale={yScale}
          hideTicks
          hideAxisLine
          left={AUMGraphConstants.axis.left}
          top={AUMGraphConstants.axis.top}
          tickLabelProps={tickLabelProps}
          tickFormat={accessors.formatY}
        />
        <GraphAreas
          keys={keys}
          data={areasData}
          xScale={xScale}
          yScale={yScale}
          accessors={accessors}
          groupingOption={groupingOption}
        />
        <HorizontalGridRows yScale={yScale} graphConstants={AUMGraphConstants} />
        <HorizontalGridRows yScale={cashFlowScale} graphConstants={CashFlowGraphConstants} />
        <GraphXAxisSeparator
          x={SeparatorConstants.graphSeparator.x}
          y={AUMGraphConstants.height + SeparatorConstants.graphSeparator.yModifier + 10}
          width={SeparatorConstants.graphSeparatorWidth - SeparatorConstants.graphSeparator.widthModifier}
          color="#F2F2F2"
        />
        <GraphLabel
          x={SeparatorConstants.graphSeparator.x}
          y={AUMGraphConstants.height + SeparatorConstants.graphSeparator.yModifier + 2}
          text={"Asset Flows"}
          color="#7094BE"
        />
        <GraphXAxisSeparator
          x={SeparatorConstants.margin.left}
          y={AUMGraphConstants.height + AUMGraphConstants.margin.bottom + CashFlowGraphConstants.height}
          width={SeparatorConstants.axisSeparatorWidth}
          color="#E4E3E9"
        />
        <Axis
          orientation={Orientation.left}
          scale={cashFlowScale}
          hideTicks
          hideAxisLine
          left={52}
          top={cashFlowRanges.max === 0 ? 10 : 0}
          tickLabelProps={tickLabelProps}
          tickFormat={accessors.formatY}
        />
        <GraphCashFlowBars
          branchData={areasData}
          movementsData={movementsData}
          xScale={xScale}
          cashFlowScale={cashFlowScale}
          accessors={accessors}
          graphConstants={CashFlowGraphConstants}
          xAxisY={
            AUMGraphConstants.height +
            AUMGraphConstants.margin.bottom +
            CashFlowGraphConstants.height +
            CashFlowGraphConstants.margin.bottom
          }
        />
        {movementsData.map((movement: any, index: number) => {
          return (
            <GraphHoverBar
              key={index}
              index={index}
              xScale={xScale}
              data={data.movementsData}
              accessors={accessors}
              movement={movement}
              currentX={currentX}
              barHeight={AUMGraphConstants.height + AUMGraphConstants.margin.bottom + CashFlowGraphConstants.height}
              onMouseOver={(e: any) => {
                const tooltipData = { ...areasData[index], ...movement };
                setCurrentX(index);
                handleTooltip(e, tooltipData);
              }}
              onMouseOut={() => {
                setCurrentX(undefined);
                hideTooltip();
              }}
              totalXEntries={movementsData.length}
            />
          );
        })}
      </svg>
      {tooltipOpen && (
        <Tooltip key={Math.random()} top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          {tooltipData &&
            tooltipData.length &&
            tooltipData.map((entry: any, index) => <GraphToolTipContent key={index} index={index} entry={entry} />)}
        </Tooltip>
      )}
    </>
  );
};

export default AUMGraph;
