import React, {ReactNode} from "react";
import { Flex } from "@chakra-ui/react";
import { useLayoutWidth } from "../../hooks/useLayoutWidth";

interface HeaderContainerProps {
  children?: ReactNode;
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({ children }) => {
  const width = useLayoutWidth();

  return (
    <Flex h={135} w="100%" justify="center">
      <Flex direction="row" sx={styles.header} width={width}>
        <Flex marginX={9} marginY={7} flex={1}>
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

const styles = {
  header: {
    zIndex: 1,
    boxShadow: "0px 4px 12px 0px rgba(0,0,0,0.10)",
  },
};

export default HeaderContainer;
