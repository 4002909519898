import React from "react";

interface GraphXAxisSeparatorProps {
  x: number;
  y: number;
  width: number;
  color: string;
}

const GraphXAxisSeparator: React.FC<GraphXAxisSeparatorProps> = ({ x, y, width, color }) => {
  return <rect x={x} y={y} width={width} fill={"gray"} stroke={color} height={0.5} />;
};

export default GraphXAxisSeparator;
