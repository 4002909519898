import React, {ReactNode} from "react";
import { AccordionButton, AccordionItem, AccordionPanel, Text, AccordionIcon } from "@chakra-ui/react";
import styled from "styled-components";

interface SidebarAccordionProps {
  name: string;
  highlight: boolean;
  borderColor?: string;
  scroll?: boolean;
  children: ReactNode;
}

const Accordion: React.FC<SidebarAccordionProps> = ({
  name,
  children,
  highlight,
  borderColor = "grey_50",
  scroll = true,
}) => {
  return (
    <AcordionContainer className="acco" borderColor={borderColor} sx={styles(highlight)}>
      <AccordionButton pt={4} pb={4} px={0}>
        <AccordionIcon />
        <Text textStyle="sortTitle" fontWeight="700" color={highlight ? "accent_1_900" : "white"}>
          {name}
        </Text>
      </AccordionButton>
      <AccordionPanel px={1} mb={2} maxHeight={400} overflowY={scroll ? "scroll" : "inherit"} overflowX="hidden">
        {children}
      </AccordionPanel>
    </AcordionContainer>
  );
};

const styles = (highlight: boolean) => {
  return {
    ".chakra-icon": {
      color: highlight ? "accent_1_900" : "white",
      marginLeft: "-2px",
      marginRight: "6px",
    },
  };
};

const AcordionContainer = styled(AccordionItem)`
  &:last-of-type {
    border-bottom: 0;
  }
`;

export default Accordion;
