import React from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import SummaryStack from "../../components/summary-stack";
import Loader from "../../components/loader";
import { TopSalesRep } from "../../types/top-sales-rep";
import styled from "styled-components";
import { formatNumber } from "../../utils/utils";
import { useMutateFilters } from "../../hooks/useMutateFilters";
import { filtersAtom } from "../../states/filters";
import { FiltersType } from "../../enums/enums";
import NoDataFound from "../../components/graph/no-data-found";
import { useAtom } from "jotai";
interface TopSalesProps {
  data: TopSalesRep[] | undefined;
  status: string;
  sectionTitle: string;
}

const TopSales: React.FC<TopSalesProps> = ({ data, status, sectionTitle }) => {
  const [filters] = useAtom(filtersAtom);
  const { onClickChartOption } = useMutateFilters();
  return (
    <Flex layerStyle="shadowBox" sx={{ width: 185 }}>
      <Text textStyle="sectionTitle">{sectionTitle}</Text>
      <Box mb={5} />
      {status !== "success" ? (
        <Loader flexProps={{ alignItems: "start", mt: "6" }} />
      ) : status === "success" && !data?.length ? (
        <NoDataFound />
      ) : (
        <Flex justify="space-between" direction="column">
          {data?.map((rep, index) => (
            <SummaryStack key={`${rep}-${index}`}>
              <Wrapper>
                <RepName
                  textStyle="bodySmallLink"
                  onClick={() => onClickChartOption(filters, FiltersType.REPS, rep.name)}
                >
                  {rep.name.toUpperCase()}
                </RepName>
                <Text textStyle="bodyBold">${formatNumber(parseFloat(rep.total), "en-US")}</Text>
              </Wrapper>
            </SummaryStack>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const RepName = styled(Text)`
  cursor: pointer;
`;
export default TopSales;
