import { useQuery } from "react-query";
import axios from "axios";
import { Filters } from "../../states/filters";
import { ProductionDashboardSummary } from "../../types/production-dashboard";
import { getAPIURL } from "../../core/api";
import { getFiltersForBackend } from "../../core/filters";

export default function usePDSummary(filters: Filters) {
  return useQuery(["production-dashboard-summary", filters], () => fetch(filters));
}

/**
 * @api {post} /production-dashboard/summary Summary
 * @apiGroup Production Dashboard
 * @apiSampleRequest off
 *
 * @apiParam {Object} filters
 * @apiParam {Date} filters.startDate
 * @apiParam {Date} filters.endDate
 * @apiParam {String[]} filters.branches="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.reps="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.businesses="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.securities="IDs to select. If empty, all are used."
 *
 * @apiSuccessExample {json} Success-Response:
 *  HTTP/1.1 200 OK
 *  {
 *     totalProduction: 402403300;
 *     YTD: {
 *      value: 100000,
 *      change: 0.3
 *     },
 *     YTDYOY: {
 *      value: 100000,
 *      change: 0.3
 *     },
 *     MTD: {
 *      value: 100000,
 *      change: 0.3
 *     },
 *     MTDYOY: {
 *      value: 100000,
 *      change: 0.3
 *     },
 *     years: [
 *      {
 *        year: "2019",
 *        value: 100000
 *      },
 *      {
 *        year: "2020",
 *        value: 100000
 *      }
 *     ];
 *  }
 */

const fetch = async (filters: Filters): Promise<ProductionDashboardSummary> => {
  const newFilters = getFiltersForBackend(filters);
  const response = await axios.post(getAPIURL("/production-dashboard/summary"), {
    ...newFilters,
  });

  return response.data;
};