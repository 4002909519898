import React from "react";
import styled from "styled-components";
import { Tooltip } from '@visx/tooltip';
import { DataSchema } from "./graph";
import { Flex, Text } from "@chakra-ui/react";
import { formatNumber } from "../../../utils/utils";
import { theme } from "../../../theme";
interface GraphTooltipProps {
    top: number | undefined;
    left: number | undefined;
    data: DataSchema | undefined;
}

const GraphTooltip: React.FC<GraphTooltipProps> = ({top, left, data}) => {
    return (
        <>
            <PieTooltip
                key={Math.random()}
                top={top}
                left={left}
            >   
                {data && (
                    <Flex flexDirection="column" alignContent="center" alignItems="center">
                        <Text fontSize="xs" color="#7094BE" mb={1}>
                            {`${formatNumber(data.total, "en-US")}`}
                        </Text>
                        <Text fontSize="xs" color="#7094BE" mb={1}>
                            {`${data.percentage}%`}
                        </Text>
                    </Flex>
                )}
                <TooltipTriangleContainer />
            </PieTooltip>
        </>
    );
}

const PieTooltip = styled(Tooltip)`
    background-color: ${theme.colors.accent_4_50} !important;
    width: 125px;
`;

const TooltipTriangleContainer = styled.div`
    position: relative;
    top: 4px;
    
    &:after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        left: 15px;
        border-top: 15px solid ${theme.colors.accent_4_50};
        border-right: 15px solid transparent;
        border-bottom: 15px solid transparent;
    }
`;

export default GraphTooltip;