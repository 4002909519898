import React, { ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import { filtersAtom } from "../../states/filters";
import { RadioButtonItem } from "../../types/form";
import { Flex, Radio, Text } from "@chakra-ui/react";
import { RadioGroup } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { currentDashboard } from "../../states/routes";
import { CustomDateRanges, MenuOptions } from "../../enums/enums";
import { useMutateFilters } from "../../hooks/useMutateFilters";

const CustomRadioButtonsWrap = styled(Flex)`
  .chakra-radio {
    visibility: visible;
  }
  .chakra-radio__control {
    visibility: hidden;
  }
`;

interface CustomDateRangeProps {
  radiobuttons: RadioButtonItem[];
  setRadioButtons: Function;
  setStartDate: Function;
  setEndDate: Function;
  setRadioButtonValue: (nextValue: string) => void;
  value: string | undefined;
  filtersID: string;
}

const CustomDateRange: React.FC<CustomDateRangeProps> = ({
  radiobuttons,
  filtersID,
  setRadioButtons,
  setStartDate,
  setEndDate,
  setRadioButtonValue,
  value,
}) => {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [dashboard] = useAtom(currentDashboard);
  const { onSetYearToDate, onSetMonthDate, onSetQuarterToDate, onSetPriorQuarter } = useMutateFilters();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value !== "" ? e.target.value : radiobuttons[0].value;
    const nextRadioButtons = radiobuttons.map((radiobutton) => {
      return { ...radiobutton, checked: radiobutton.value === value };
    });

    updateState(nextRadioButtons);
  };

  const updateState = (radiobuttons: RadioButtonItem[]) => {
    if (!radiobuttons.length) return;

    setRadioButtons(radiobuttons);
    setFilters({ ...filters, [filtersID]: radiobuttons });
  };

  useEffect(() => {
    switch (value) {
      case CustomDateRanges.YEAR_TO_DATE:
        onSetYearToDate(filters, setStartDate, dashboard as MenuOptions, setEndDate);
        break;
      case CustomDateRanges.MONTH_TO_DATE:
        onSetMonthDate(filters, setStartDate, dashboard as MenuOptions, setEndDate);
        break;
      case CustomDateRanges.PRIOR_QUARTER:
        onSetPriorQuarter(filters, setStartDate, dashboard as MenuOptions, setEndDate);
        break;
      default:
      case CustomDateRanges.QUARTER_TO_DATE:
        onSetQuarterToDate(filters, setStartDate, dashboard as MenuOptions, setEndDate);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, dashboard]);

  return (
    <RadioGroup onChange={setRadioButtonValue} value={value} mb={4} mt={1}>
      <CustomRadioButtonsWrap flexWrap="wrap">
        {radiobuttons.map((radiobutton, index) => (
          <Radio
            key={`${radiobutton.value}-${index}`}
            value={radiobutton.value}
            isChecked={radiobutton.checked}
            onChange={onChange}
            onClick={() => radiobutton.action(filters, setStartDate, dashboard, setEndDate)}
            isDisabled={radiobutton.disabled}
            visibility="hidden"
            marginLeft="-22px"
          >
            <Text
              cursor={radiobutton.disabled ? "auto" : "pointer"}
              bg={radiobutton.checked ? "accent_1_900" : "grey_900"}
              _hover={{
                bg: radiobutton.disabled ? "grey_900" : "accent_3_900",
              }}
              textStyle="sortCategory"
              color={radiobutton.checked ? "white" : "accent_1_900"}
              textTransform="capitalize"
              w={20}
              mb={2}
              mr={2}
              textAlign="center"
              borderRadius="md"
              fontSize="sm"
              py={0.5}
            >
              {radiobutton.name}
            </Text>
          </Radio>
        ))}
      </CustomRadioButtonsWrap>
    </RadioGroup>
  );
};

export default CustomDateRange;
