import React from "react";
import { Flex, Grid, GridItem, HStack, Text } from "@chakra-ui/react";
import SectionTitle from "../../components/section-title";
import SummaryStack from "../../components/summary-stack";
import moment from "moment";
import usePDSummary from "../../hooks/production-dashboard/usePDSummary";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";
import Loader from "../../components/loader";
import { formatNumber } from "../../utils/utils";

interface SummaryProps {}

const Summary: React.FC<SummaryProps> = () => {
  const [filters] = useAtom(filtersAtom);
  const { status, data } = usePDSummary(filters);
  const yesterdayLabel = moment(filters.endDate).format("l");

  return (
    <Flex layerStyle="shadowBox">
      <SectionTitle label={`Total Production`} />
      <Flex justifyContent="space-between" h="80px">
        {status !== "success" ? (
          <Loader />
        ) : (
          data && (
            <>
              <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle">{`AS OF ${yesterdayLabel}`}</Text>
                    <Text textStyle="aumTotal">${formatNumber(data?.totalProduction, "en-US")}</Text>
                  </SummaryStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle">{`MTD`}</Text>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.MTD.value, "en-US")}</Text>
                    </HStack>
                    <Text textStyle="figureTitle">{`YOY`}</Text>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.MTDYOY.value, "en-US")}</Text>
                    </HStack>
                  </SummaryStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <Text textStyle="figureTitle">{`YTD`}</Text>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.YTD.value, "en-US")}</Text>
                    </HStack>
                    <Text textStyle="figureTitle">{`YOY`}</Text>
                    <HStack>
                      <Text textStyle="bodyBold">${formatNumber(data?.YTDYOY.value, "en-US")}</Text>
                    </HStack>
                  </SummaryStack>
                </GridItem>
                <GridItem w="100%">
                  <SummaryStack>
                    <HStack gap={10}>
                      {data?.years
                        .sort((a, b) => b.year.localeCompare(a.year))
                        .map((obj, index) => (
                          <SummaryStack key={`${obj}-${index}`}>
                            <Text textStyle="figureTitle">{obj.year}</Text>
                            <HStack>
                              <Text textStyle="bodyBold">${formatNumber(obj.value, "en-US")}</Text>
                            </HStack>
                          </SummaryStack>
                        ))}
                    </HStack>
                    <SummaryStack>
                      <Text textStyle="figureTitle">T12</Text>
                      <HStack>
                        <Text textStyle="bodyBold">{formatNumber(data?.T12.value, "en-US")}</Text>
                      </HStack>
                    </SummaryStack>
                  </SummaryStack>
                </GridItem>
              </Grid>
            </>
          )
        )}
      </Flex>
    </Flex>
  );
};

export default Summary;
