import { useEffect, useState } from "react";
import { FiltersType } from "../enums/enums";
import useAccounts from "./useAccounts";
import { useMutateFilters } from "./useMutateFilters";
import { Filters } from "../states/filters";
import { filtersAtom } from "../states/filters";
import { createCheckboxesFromJSON } from "../core/sidebar";
import { useAtom } from "jotai";
import { trimText } from "../utils/utils";

export function useAccountSelection() {
  const { mutate, data } = useAccounts();
  const [filtersAt] = useAtom(filtersAtom);
  const [filters, setFilters] = useState<Filters>(filtersAt);
  const [selectedOption, setSelectedOption] = useState<string | undefined>("");
  const { onSelectAccount } = useMutateFilters();

  useEffect(() => {
    const accounts = createCheckboxesFromJSON(data ? data : []);
    onSelectAccount(filters, FiltersType.ACCOUNTS, accounts, selectedOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filters, selectedOption]);

  const onSetAccount = (filters: Filters, term: string, selectedOption: string | undefined) => {
    mutate(term);
    setFilters(filters);
    setSelectedOption(trimText(selectedOption));
  };

  return { onSetAccount };
}
