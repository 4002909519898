import React from "react";
import styled from "styled-components";
import { Box, Stack } from "@chakra-ui/react";
import { theme } from "../../../theme";

export interface GraphGroupingOption {
  label: string;
  value: string;
  selected: boolean;
}

interface GraphGroupingProps {
  options: GraphGroupingOption[];
  onClickOption: Function;
}

const GraphGrouping: React.FC<GraphGroupingProps> = ({ options, onClickOption }) => {
  return (
    <TabWrapper direction={["column", "row"]} spacing="0" h="23px" justify="center" alignItems="center">
      {options.map((option, index) => (
        <Tab key={index} isactive={option.selected ? "true" : "false"} onClick={() => onClickOption(option)}>
          {option.label}
        </Tab>
      ))}
    </TabWrapper>
  );
};

const TabWrapper = styled(Stack)`
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
`;

interface TabProps {
  isactive: string;
}

const Tab = styled(Box)<TabProps>`
  user-select: none;
  color: ${theme.colors.grey_50};
  padding: 0px 12px;
  font-size: 12px;
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.bold};
  height: 23px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-right: 2px solid ${theme.colors.grey_4_50};

  &:last-child {
    border-right: unset;
  }

  ${(props) =>
    props.isactive === "true"
      ? `font-weight: ${theme.fontWeights.bold}; background-color: ${theme.colors.accent_4_50}; height: 20px`
      : ""}
`;

export default GraphGrouping;
