import styled from "styled-components";
import { Box, ModalContent, Th } from "@chakra-ui/react";
import { theme } from "../../../../theme";

export const Content = styled(ModalContent)`
  max-width: 85%;
  .chakra-modal__body::-webkit-scrollbar {
    width: 0.5em;
  }

  .chakra-modal__body::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.accent_3_50};
    outline: 1px solid ${theme.colors.accent_3_50};
  }
`;

export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
`;

export const ColumnHeader = styled(Th)`
  cursor: pointer;

  svg {
    margin-left: 3px;
  }
`;

export const ColumnHeaderCtn = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: end;

  svg {
    margin-bottom: 2px;
  }
`;
