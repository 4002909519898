import React from "react";
import GraphLoader from "../global/mix-of-business/graph-loader";
import useMOBGraphData from "../../hooks/production-dashboard/useMOBGraphData";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";

interface MixOfBusinessProps {}

const MixOfBusiness: React.FC<MixOfBusinessProps> = () => {
    const [filters] = useAtom(filtersAtom);
    const { status, data } = useMOBGraphData(filters);
    return (
        <GraphLoader data={data} status={status} />
    );
}

export default MixOfBusiness;