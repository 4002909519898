import React from "react";
import { Page } from "../../components/layout/page";
import Summary from "./summary";
import TopPerformersGross from "./top-performers-gross";
import MixOfBusiness from "./mix-of-business";
import GraphLoader from "./graph/graph-loader";
import TopAccounts from "./top-accounts";
import { privileges } from "../../states/privileges";
import { Privileges } from "../../enums/enums";
import { useAtom } from "jotai";

interface ProductionDashboardProps {}

const ProductionDashboard: React.FC<ProductionDashboardProps> = () => {
  const [rol] = useAtom(privileges);
  const isAdvisor = rol === Privileges.SALES_REPRESENTATIVE;

  return (
    <Page
      mainComponents={
        <>
          <Summary />
          <GraphLoader />
          <MixOfBusiness />
        </>
      }
      sidebarComponent={isAdvisor ? <TopAccounts /> : <TopPerformersGross />}
    />
  );
};

export default ProductionDashboard;
