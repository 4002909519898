import React, { useState } from "react";
import { Flex, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { MenuOptions } from "../../enums/enums";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../core/routes";
import { theme } from "../../theme";
import { currentDashboardPersistence } from "../../states/routes";
import { useAtom } from "jotai";

interface PageSwitcherProps {}

const PageSwitcher: React.FC<PageSwitcherProps> = () => {
  const [currentPersistence, setDashboardPersistence] = useAtom(currentDashboardPersistence);
  const [selectedOption, setSelectedOption] = useState<string>(currentPersistence);
  const menuOptions: string[] = [MenuOptions.ASSETS_UNDER_MANAGEMENT, MenuOptions.PRODUCTION_DASHBOARD];

  const selectionHandler = (option: string) => {
    setDashboardPersistence(option);
    setSelectedOption(option);
  };

  let location = useLocation();

  React.useEffect(() => {
    if (location.pathname !== pathHandler(selectedOption)) {
      switch(location.pathname) {
        case routes.assetsUnderManagement:
          selectionHandler(MenuOptions.ASSETS_UNDER_MANAGEMENT);
          break;

        case routes.productionDashboard:
          selectionHandler(MenuOptions.PRODUCTION_DASHBOARD);
          break;
      }
    }
  }, [location]);

  const pathHandler = (option: string) => {
    let path = "";

    switch (option) {
      case MenuOptions.ASSETS_UNDER_MANAGEMENT:
        path = routes.assetsUnderManagement;
        break;
      case MenuOptions.PRODUCTION_DASHBOARD:
        path = routes.productionDashboard;
        break;
    }

    return path;
  };

  return (
    <Flex marginLeft={10} alignItems="flex-end" flex={1}>
      <Menu>
        <Flex justify="center" alignItems="center">
          <Button sx={{ cursor: "pointer" }}>
            {selectedOption}
            <ArrowDown />
          </Button>
        </Flex>
        <CustomMenuList>
          {menuOptions.map(
            (option, index) =>
              selectedOption !== option && (
                <CustomMenuItem onClick={() => selectionHandler(option)} key={`${option}-${index}`}>
                  <Anchor to={pathHandler(option)}>{option}</Anchor>
                </CustomMenuItem>
              )
          )}
        </CustomMenuList>
      </Menu>
    </Flex>
  );
};

const CustomMenuList = styled(MenuList)`
  border: 0;
  border-radius: 0;
  position: absolute;
  top: 20px;
  width: 515px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.06);

  &:hover {
    background: ${theme.colors.grey_3_50};
  }
`;

const CustomMenuItem = styled(MenuItem)`
  font-weight: 700;
  font-size: 24px;
  color: ${theme.colors.grey_2_50};

  &:active,
  &:focus {
    background: unset;
  }

  &:hover {
    color: ${theme.colors.blue_1_50};
  }
`;

const ArrowDown = styled.div`
  border: solid ${theme.colors.grey_2_50};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 17px;
  margin-top: 7px;
`;

const Anchor = styled(Link)`
  width: 100%;
`;

const Button = styled(MenuButton)`
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes["4xl"]};
  line-height: ${theme.lineHeights[7]};
  color: ${theme.colors.primary900};

  span {
    display: flex;
    flex-direction: row;
    align-items: start;
  }
`;

export default PageSwitcher;
