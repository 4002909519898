import { useState } from "react";
import { Transaction } from "../types/transaction";

export function useSortable() {
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);

  const onSetTransactions = (data: Array<Transaction>) => setTransactions(data);

  const onSortTransactions = (key: string, data: Array<Transaction>, isAsc: boolean) => {
    switch (key) {
      case "trade_date":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return a.trade_date.localeCompare(b.trade_date);
            return b.trade_date.localeCompare(a.trade_date);
          })
        );
        break;
      case "settlement_date":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return a.settlement_date.localeCompare(b.settlement_date);
            return b.settlement_date.localeCompare(a.settlement_date);
          })
        );
        break;
      case "account_number":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return a.account_number.localeCompare(b.account_number);
            return b.account_number.localeCompare(a.account_number);
          })
        );
        break;
      case "account_title":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return a.account_title.localeCompare(b.account_title);
            return b.account_title.localeCompare(a.account_title);
          })
        );
        break;
      case "cusip":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return a.cusip.localeCompare(b.cusip);
            return b.cusip.localeCompare(a.cusip);
          })
        );
        break;
      case "cusip_desc":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return a.cusip_desc.localeCompare(b.cusip_desc);
            return b.cusip_desc.localeCompare(a.cusip_desc);
          })
        );
        break;
      case "gross_comission":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return a.gross_comission - b.gross_comission;
            return b.gross_comission - a.gross_comission;
          })
        );
        break;
      case "transaction_price":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return Number(a.transaction_price) - Number(b.transaction_price);
            return Number(b.transaction_price) - Number(a.transaction_price);
          })
        );
        break;
      case "transaction_quantity":
        setTransactions(
          data.sort((a, b) => {
            if (isAsc) return Number(a.transaction_quantity) - Number(b.transaction_quantity);
            return Number(b.transaction_quantity) - Number(a.transaction_quantity);
          })
        );
        break;
    }
  };

  return {
    onSetTransactions,
    onSortTransactions,
    transactions,
  };
}
