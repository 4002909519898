/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import { max } from "d3-array";
import { BarStack } from "@visx/shape";
import { Group } from "@visx/group";
import { GridRows } from "@visx/grid";
import { useTooltip, Tooltip } from "@visx/tooltip";
import { Box, useDisclosure } from "@chakra-ui/react";
import { getColorForArea, getColorForSecurities, getColorForAccounts } from "../../../core/color";
import GraphAxis from "./graph-axis";
import { tooltipStyles } from "../../../components/tooltip";
import GraphTooltipContent from "./graph-tooltip-content";
import { formatNumber, formatValues, formatDates } from "../../../utils/utils";
import useGraphTable from "../../../hooks/production-dashboard/useGraphTable";
import GraphModalTable from "./modal/graph-modal-table";
import { privileges } from "../../../states/privileges";
import { useAtom } from "jotai";
import moment from "moment";
import { useSortable } from "../../../hooks/useSortable";
import { Privileges } from "../../../enums/enums";
import { windowsSizeAtom } from "../../../states/dimensions";
import { WINDOW_WIDTH_1366, WINDOW_WIDTH_1600 } from "../../../theme";

interface GraphProps {
  data: any; // TODO type?
  groupingOption: string;
}

type BranchName = "Boca" | "Austin" | "Fairfield" | "Iselin" | "Guaynabo" | "Bloomington" | "Jupiter";

const getDate = (d: any) => d.date;

const formatY = (value: number) => {
  const label = value >= 0 ? formatNumber(value, "en-US") : formatNumber(value * -1, "en-US");
  return value >= 0 ? `$${label}` : `($${label})`;
};

const formatX = (value: any) => {
  formatDates(value);
};

const Graph: React.FC<GraphProps> = ({ data, groupingOption }) => {
  const [windowSize] = useAtom(windowsSizeAtom);
  let graphWidth = 750;

  if (windowSize.width >= WINDOW_WIDTH_1366) {
    graphWidth = 760;
  }

  if (windowSize.width >= WINDOW_WIDTH_1600) {
    graphWidth = 980;
  }

  const graph = { width: graphWidth, height: 450, margin: { top: 0, right: 0, bottom: 40, left: 40 } };
  const yMax = graph.height - graph.margin.top - graph.margin.bottom;
  const xMax = graph.width - graph.margin.left - graph.margin.right;

  const { areasData, keys } = data;
  const { mutate, data: tableData } = useGraphTable();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { transactions, onSetTransactions } = useSortable();
  const [rol] = useAtom(privileges);
  const isAdvisor = rol === Privileges.SALES_REPRESENTATIVE;

  formatValues(areasData, keys);
  const colorScale = scaleOrdinal<BranchName, string>({
    domain: keys,
    range: keys.map((key: any) =>
      groupingOption === "Branch"
        ? getColorForArea(key, keys.indexOf(key))
        : groupingOption === "Security Type"
        ? getColorForSecurities(key, keys.indexOf(key))
        : groupingOption === "Business Type"
        ? getColorForAccounts(key, keys.indexOf(key))
        : getColorForArea(key, keys.indexOf(key))
    ),
  });

  const xScale = scaleBand<string>({ domain: areasData.map(getDate), padding: 0.2 });
  xScale.range([graph.margin.left + 50, xMax]);

  const getMaxY = () => {
    const totals = areasData.map((item: any) => {
      let total: number = 0;
      keys.forEach((k: string) => {
        if (k !== "date") {
          if (parseInt(item[k]) >= 1) {
            total += parseInt(item[k]);
          }
        }
      });

      return total;
    });
    return Number(max(totals));
  };

  const maxY = getMaxY();

  const yScale = scaleLinear<number>({ domain: [0, maxY], nice: false });
  yScale.range([yMax, 0]);

  const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop, tooltipOpen } = useTooltip({
    tooltipOpen: false,
    tooltipData: {} as any,
  });

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>, data: any) => {
      const rect = event.currentTarget.getBoundingClientRect();
      const branches = Object.entries(data);
      const mouseEvent = event as React.MouseEvent<SVGRectElement>;

      showTooltip({
        tooltipData: branches,
        tooltipLeft: rect.x + 12,
        tooltipTop: mouseEvent.pageY - 10,
      });
    },
    [data]
  );

  const isClickable = (data: any) => {
    const date = data.date.split("-");
    return date.length >= 2;
  };

  const showGraphTable = (data: any) => {
    if (isClickable(data)) {
      onOpen();
      mutate(moment(data.date).toISOString());
    }
  };

  useEffect(() => {
    onSetTransactions(tableData);
  }, [tableData]);

  return (
    <div>
      <svg width={graph.width} height={graph.height}>
        <rect x={0} y={0} width={graph.width} height={graph.height} fill="transparent" />
        <GridRows
          left={90}
          scale={yScale}
          width={graph.width - 130}
          strokeDasharray="4,3"
          strokeWidth={0.5}
          stroke="#979797"
          strokeOpacity={0.4}
          pointerEvents="none"
          className="row-grid"
        />
        <Group top={graph.margin.top}>
          <BarStack<any, any>
            data={areasData}
            keys={keys}
            x={getDate}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar, index) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x + (bar.width - 12) / 2}
                    y={bar.y}
                    height={bar.height}
                    width={12}
                    onClick={() => showGraphTable(bar.bar["data"])}
                    cursor={isClickable(bar.bar["data"]) ? "pointer" : "default"}
                    fill={
                      groupingOption === "Branch"
                        ? getColorForArea(bar.key, keys.indexOf(bar.key))
                        : groupingOption === "Security Type"
                        ? getColorForSecurities(bar.key, keys.indexOf(bar.key))
                        : groupingOption === "Business Type"
                        ? getColorForAccounts(bar.key, keys.indexOf(bar.key))
                        : getColorForArea(bar.key, keys.indexOf(bar.key))
                    }
                    onMouseOver={(e) => {
                      const tooltipData = { ...areasData[index] };
                      handleTooltip(e, tooltipData);
                    }}
                    onMouseOut={() => {
                      hideTooltip();
                    }}
                  />
                ))
              )
            }
          </BarStack>
        </Group>
        <GraphAxis branchesScale={yScale} dateScale={xScale} yMax={yMax} formatX={formatX} formatY={formatY} />
      </svg>
      {tooltipOpen && tooltipData && (
        <Tooltip key={Math.random()} top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          {tooltipData.map((entry: any, index: number) => {
            return entry[0] === "date" ? (
              <Box key={index} />
            ) : (
              <Box key={index}>
                <GraphTooltipContent type={entry[0]} value={entry[1]} />
              </Box>
            );
          })}
        </Tooltip>
      )}
      {isOpen && isAdvisor && <GraphModalTable isOpen={isOpen} onClose={onClose} data={transactions} />}
    </div>
  );
};

export default Graph;
