import React from "react";
import { Flex, Text } from "@chakra-ui/react";

interface GraphHeaderProps {
  sectionTitle: string;
  rightSide: React.ReactNode;
}

const GraphHeader: React.FC<GraphHeaderProps> = ({ sectionTitle, rightSide }) => {
  return (
    <Flex direction="row" layerStyle="sectionBox" justify="space-between" width="100%">
      <Text textStyle="sectionTitle">{sectionTitle}</Text>
      {rightSide}
    </Flex>
  );
};

export default GraphHeader;
