import { GraphGroupingOption } from "../pages/global/graph/graph-grouping";

export const onClickGraphGroupingOption = (
  graphGroupingOptions: GraphGroupingOption[],
  selectedOption: GraphGroupingOption,
  setGraphGroupingOptions: Function
) => {
  const nextOptions = graphGroupingOptions.map((option) => ({
    ...option,
    selected: option.value === selectedOption.value,
  }));

  setGraphGroupingOptions(nextOptions);
};

export const getSelectedGraphGroupingOption = (graphGroupingOptions: GraphGroupingOption[]): GraphGroupingOption => {
  return graphGroupingOptions.find((option) => option.selected) as GraphGroupingOption;
};
