import React from "react";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import { theme } from "../../../theme";

interface GraphLegendProps {}

const GraphLegend: React.FC<GraphLegendProps> = () => {
  return (
    <Flex flexDirection="row" justifyContent="flex-end" mt={4}>
      <HStack spacing="3">
        <Flex direction="row" alignItems="center">
          <Box borderWidth={2} borderColor="#919195" width={6} height={0} mr={2} />
          <Text textStyle="legend">Net Worth</Text>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box borderWidth={1} borderColor={theme.colors.primary50} h={2} width={6} mr={2} borderRadius={3} />
          <Text textStyle="legend">ACATS In</Text>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box
            borderWidth={1}
            borderColor={theme.colors.primary900}
            backgroundColor={theme.colors.primary900}
            h={2}
            width={6}
            mr={2}
            borderRadius={3}
          />
          <Text textStyle="legend">ACATS Out</Text>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box
            borderWidth={1}
            borderColor="#919195"
            backgroundColor="#919195"
            h={2}
            width={6}
            mr={2}
            borderRadius={3}
          />
          <Text textStyle="legend">Withdrawals</Text>
        </Flex>
        <Flex direction="row" alignItems="center">
          <Box
            borderWidth={1}
            borderColor={theme.colors.accent_3_900}
            backgroundColor="#E4E3E9"
            h={2}
            width={6}
            mr={2}
            borderRadius={3}
          />
          <Text textStyle="legend">Deposits</Text>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default GraphLegend;
