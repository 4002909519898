import { useQuery } from "react-query";
import axios from "axios";
import { getAPIURL } from "../core/api";
import { User } from "../types/user";

export default function useAvailableUsers(user: User) {
  return useQuery(["available-users", user && user.id], () => fetch(user), { enabled: false });
}

const fetch = async (user: User): Promise<User[]> => {
  const response = await axios.get(getAPIURL(`/users/available?user-id=${user.id}`), {});

  return response.data;
};
