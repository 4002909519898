import { extendTheme } from "@chakra-ui/react";

export const WINDOW_WIDTH_1280 = 1280;
export const WINDOW_WIDTH_1366 = 1366;
export const WINDOW_WIDTH_1600 = 1600;

export const theme = extendTheme({
  shadows: { outline: "0 !important" }, // TODO check if this overrides other shadow styles
  colors: {
    primary900: "#42474A",
    primary50: "#AEB2B5",
    secondary900: "#7094BE",
    accent_1_900: "#BDC73F",
    accent_3_900: "#888887",
    accent_3_50: "#E0E0E0",
    accent_4_50: "#F2F2F2",
    accent_1_50: "#E9EFA0",
    grey_900: "#606162",
    grey_50: "#979797 ",
    grey_1_50: "#E4E3E9",
    grey_2_50: "#C4C4C4",
    grey_3_50: "#F0F0F0",
    grey_4_50: "#D8D8D8",
    blue_900: "#24548C",
    blue_50: "#B4CAE4",
    blue_1_50: "#7094BE",
    yellow_900: "#FFD74B",
    yellow_50: "#FFF4CD",
    orange_900: "#E3631B",
    pink_50: "#FFCDB1",
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
  },
  textStyles: {
    h1: {
      fontSize: "4xl",
      fontWeight: "700",
      color: "primary900",
    },
    sectionTitle: {
      fontSize: "md",
      fontWeight: "700",
      color: "secondary900",
    },
    figureTitle: {
      fontSize: "xs",
      fontWeight: "400",
      color: "accent_3_900",
      textTransform: "uppercase",
    },
    bodyRegular: {
      fontSize: "md",
      fontWeight: "400",
      color: "accent_3_900",
    },
    bodyBold: {
      fontSize: "md",
      fontWeight: "700",
      color: "primary900",
    },
    bodySmallLink: {
      fontSize: "xs",
      fontWeight: "400",
      color: "secondary900",
    },
    aumTotal: {
      fontSize: "2xl",
      fontWeight: "700",
      color: "primary900",
    },
    sortTitle: {
      fontSize: "md",
      color: "white",
    },
    sortCategory: {
      fontSize: "md",
      color: "white",
      lineHeight: "normal",
    },
    legend: {
      fontSize: "xs",
      color: "primary900",
    },
  },
  layerStyles: {
    shadowBox: {
      bg: "white",
      boxShadow: "2px 2px 4px -1px rgba(0,0,0,0.10)",
      padding: "6",
      flexDirection: "column",
    },
    sectionBox: {
      paddingBottom: "5",
      borderBottomWidth: "2px",
      borderBottomColor: "accent_3_50",
      marginBottom: "5",
    },
  },
  styles: {
    global: {
      ".sidebar ::-webkit-scrollbar": {
        width: "6px",
      },
      ".sidebar ::-webkit-scrollbar-track": {
        backgroundColor: "primary",
      },
      ".sidebar ::-webkit-scrollbar-thumb": {
        backgroundColor: "accent_3_900",
      },
    },
  },
});
