import React from "react";
import useAUMTopAccounts from "../../hooks/assets-under-management/useAUMTopAccounts";
import { useAtom } from "jotai";
import { filtersAtom } from "../../states/filters";
import TopAccounts from "../global/top-accounts";

const AUMTopAccounts = () => {
  const [filters] = useAtom(filtersAtom);
  const { status, data } = useAUMTopAccounts(filters);
  const sectionTitle = "Top Accounts";

  return <TopAccounts data={data} status={status} sectionTitle={sectionTitle} />;
};

export default AUMTopAccounts;
