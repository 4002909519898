import { formatNumber } from "../utils/utils";

export const getFinancialNumber = (number: string) => {
  const nextNumber = parseFloat(number);

  if (nextNumber < 0) {
    return `($${formatNumber(nextNumber * -1, "en-US")})`;
  }

  return `$${formatNumber(nextNumber, "en-US")}`;
};
