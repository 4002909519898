import { useMutation } from "react-query";
import axios from "axios";
import { getAPIURL } from "../core/api";
import { CheckboxItem } from "../types/form";

export default function useReps() {
  return useMutation((rep: string) => fetch(rep));
}

/**
 * @api {post} /reps Sales Reps
 * @apiGroup Assets Under Management
 *
 * @apiParam {String} token
 *
 * @apiSuccessExample {json} Success-Response:
 *  HTTP/1.1 200 OK
 *  {
 *     id: string;
 *     name: string;
 *     sales_rep_id: string;
 *  }
 *  @apiSampleRequest off
 */

const fetch = async (rep: string): Promise<CheckboxItem[]> => {
  if (rep) {
    const response = await axios.post(getAPIURL("/reps"), { rep });
    return response.data;
  }

  return [];
};
