import { useQuery } from "react-query";
import axios from "axios";
import { Filters } from "../../states/filters";
import { AssetsUnderManagementSummary } from "../../types/assets-under-management";
import { getAPIURL } from "../../core/api";
import { getFiltersForBackend } from "../../core/filters";

export default function useSummary(filters: Filters) {
  return useQuery(["assets-under-management-summary", filters], () => fetch(filters));
}

/**
 * @api {post} /assets-under-management/summary Summary
 * @apiGroup Assets Under Management
 * @apiSampleRequest off
 *
 * @apiParam {Object} filters
 * @apiParam {Date} filters.startDate
 * @apiParam {Date} filters.endDate
 * @apiParam {String[]} filters.branches="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.reps="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.businesses="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.securities="IDs to select. If empty, all are used."
 *
 * @apiSuccessExample {json} Success-Response:
 *  HTTP/1.1 200 OK
 *  {
 *     totalAUM: 432434;
 *     change: {
 *       YTD: {
 *         value: 432432;
 *         change: 0.3;
 *       };
 *       MTD: {
 *         value: 432432;
 *         change: 0.6;
 *       };
 *     };
 *     netNewAssets: {
 *       YTD: {
 *         value: 4342;
 *         change: 0.2;
 *       };
 *       MTD: {
 *         value: 5756765;
 *         change: 0.7;
 *       };
 *     };
 *     newAccounts: {
 *       YTD: 34;
 *       MTD: 12;
 *     };
 *     newHouseholds: {
 *       YTD: 12;
 *       MTD: 32;
 *     };
 *  }
 */
const fetch = async (filters: Filters): Promise<AssetsUnderManagementSummary> => {
  const newFilters = getFiltersForBackend(filters);
  const response = await axios.post(getAPIURL("/assets-under-management/summary"), {
    ...newFilters,
  });

  return response.data;
};
