import { atom } from "jotai";
import { CheckboxItem, RadioButtonItem } from "../types/form";

export interface Filters {
  [key: string]: Date | CheckboxItem[] | undefined;
  customRangeDate: RadioButtonItem[];
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  endDate: Date;
  accounts?: CheckboxItem[];
  branches?: CheckboxItem[];
  reps: CheckboxItem[];
  securities: CheckboxItem[];
  businesses: CheckboxItem[];
  YTDStartDate?: Date;
  YTDEndDate?: Date;
  MTDStartDate?: Date;
  MTDEndDate?: Date;
  QTDStartDate?: Date;
  QTDEndDate?: Date;
  PQTDStartDate?: Date;
  PQTDEndDate?: Date;
}

const emptyCheckboxFilter: CheckboxItem = { name: "", value: "", checked: false };
const emptyRadioButtonFilter: RadioButtonItem = {
  name: "",
  value: "",
  checked: false,
  action: () => {},
  disabled: false,
};

const emptyFilters = {
  customRangeDate: [emptyRadioButtonFilter],
  minDate: new Date(),
  maxDate: new Date(),
  startDate: new Date(),
  endDate: new Date(),
  branches: [emptyCheckboxFilter],
  reps: [emptyCheckboxFilter],
  securities: [emptyCheckboxFilter],
  businesses: [emptyCheckboxFilter],
};

export const initialFiltersAtom = atom<Filters>(emptyFilters);
export const filtersAtom = atom<Filters>(emptyFilters);
