import React, { Dispatch, SetStateAction, useState } from "react";
import { Filters } from "../states/filters";
import { CheckboxItem } from "../types/form";
import { FiltersType, MenuOptions } from "../enums/enums";
import { trimText } from "../utils/utils";
import moment from "moment";

const MutateFiltersContext = React.createContext<[Filters, Dispatch<SetStateAction<Filters>>] | null>(null);

function useMutateFilters() {
  const context = React.useContext(MutateFiltersContext);

  if (!context) {
    throw new Error(`useMutateFilters must be used within a MutateFiltersProvider`);
  }

  const [filters, setFilters] = context;
  const [autocompleteOptions, setAutoCompleteOptions] = useState<Array<CheckboxItem[] | undefined>>([]);

  const onClickChartOption = (currentFilters: Filters, key: FiltersType, optionId: string) => {
    const nextFilters = { ...currentFilters };
    nextFilters[key] = nextFilters[key]!.map((item: CheckboxItem) => { 
      if (item.name === optionId) {
        return {
          ...item, 
          checked: true,
          children: item.children?.map((child) => {
            return {
              ...child, checked: true
            }
          })
        };
      }
      return {...item};
    });

    setFilters(nextFilters);
  };

  const onSetAutoCompleteOptions = (currentFilters: Filters, keys: FiltersType[]) => {
    const nextFilters = { ...currentFilters };
    const options = keys.map((key) => nextFilters[key]);
    setAutoCompleteOptions(options);
  };

  const onSetCheckboxesGroup = (currentFilters: Filters, key: FiltersType, maxItems?: number) => {
    const nextFilters = { ...currentFilters };

    if (maxItems) nextFilters[key] = nextFilters[key]!.slice(0, maxItems);

    setFilters(nextFilters);
  };

  const onSelectAccount = (
    currentFilters: Filters,
    key: FiltersType,
    options: CheckboxItem[] | undefined,
    selectedOption?: string
  ) => {
    const nextFilters = { ...currentFilters };
    const foundSelectedOption = options?.find((opt) => trimText(opt.name) === selectedOption);
    if (selectedOption) {
      if (foundSelectedOption) {
        const hasOption = nextFilters[key]!.find((opt) => trimText(opt.name) === selectedOption);
        if (!hasOption) {
          nextFilters[key]!.push(foundSelectedOption);
        }
      }

      nextFilters[key] = nextFilters[key]!.map((item: CheckboxItem) => {
        if (item.checked) return { ...item, checked: item.checked };
        return { ...item, checked: trimText(item.name) === selectedOption };
      });
    }

    setFilters(nextFilters);
  };

  const onSelectByTerm = (
    currentFilters: Filters,
    key: FiltersType,
    options: CheckboxItem[] | undefined,
    selectedOption?: string
  ) => {
    const nextFilters = { ...currentFilters };

    const foundSelectedOption = options?.find((opt) => opt.name === selectedOption);

    if (selectedOption) {
      if (foundSelectedOption) {
        const hasOption = nextFilters[key]!.find((opt) => opt.name === selectedOption);
        if (!hasOption) {
          nextFilters[key]!.push(foundSelectedOption);
        }
      }

      nextFilters[key] = nextFilters[key]!.map((item: CheckboxItem) => {
        if (item.checked) {
          return {
            ...item,
            checked: true,
            children: item.children?.map((child) => {
              return { ...child, checked: child.checked };
            }),
          };
        }

        if (item.children && item.children.length && item.children.some((i) => i.checked)) {
          return {
            ...item,
            checked: item.name === selectedOption,
            children: item.children?.map((child) => {
              return { ...child, checked: child.checked ? child.checked : item.name === selectedOption };
            }),
          };
        }

        return {
          ...item,
          checked: item.name === selectedOption,
          children: item.children?.map((child) => {
            return { ...child, checked: item.name === selectedOption };
          }),
        };
      });
    }

    setFilters(nextFilters);
  };

  const onSetYearToDate = (
    currentFilters: Filters,
    setStartDate: Function,
    dashboard: MenuOptions,
    setEndDate: Function
  ) => {
    const nextFilters = { ...currentFilters };
    nextFilters["startDate"] = moment(filters.YTDStartDate)
      .subtract(dashboard === MenuOptions.ASSETS_UNDER_MANAGEMENT ? 1 : 0, "day")
      .toDate();
    nextFilters["endDate"] = moment(filters.YTDEndDate).toDate();

    setStartDate(nextFilters["startDate"]);
    setEndDate(nextFilters["endDate"]);
    setFilters(nextFilters);
  };

  const onSetMonthDate = (
    currentFilters: Filters,
    setStartDate: Function,
    dashboard: MenuOptions,
    setEndDate?: Function
  ) => {
    const nextFilters = { ...currentFilters };
    nextFilters["startDate"] = moment(filters.MTDStartDate)
      .subtract(dashboard === MenuOptions.ASSETS_UNDER_MANAGEMENT ? 1 : 0, "day")
      .toDate();
    nextFilters["endDate"] = moment(filters.MTDEndDate).toDate();

    setStartDate(nextFilters["startDate"]);
    if (setEndDate) setEndDate(nextFilters["endDate"]);
    setFilters(nextFilters);
  };

  const onSetQuarterToDate = (
    currentFilters: Filters,
    setStartDate: Function,
    dashboard: MenuOptions,
    setEndDate?: Function
  ) => {
    const nextFilters = { ...currentFilters };

    nextFilters["startDate"] = moment(filters.QTDStartDate)
      .subtract(dashboard === MenuOptions.ASSETS_UNDER_MANAGEMENT ? 1 : 0, "day")
      .toDate();
    nextFilters["endDate"] = moment(filters.QTDEndDate).toDate();

    setStartDate(nextFilters["startDate"]);
    if (setEndDate) setEndDate(nextFilters["endDate"]);

    setFilters(nextFilters);
  };

  const onSetPriorQuarter = (
    currentFilters: Filters,
    setStartDate: Function,
    dashboard: MenuOptions,
    setEndDate?: Function
  ) => {
    const nextFilters = { ...currentFilters };

    nextFilters["startDate"] = moment(filters.PQTDStartDate)
      .subtract(dashboard === MenuOptions.ASSETS_UNDER_MANAGEMENT ? 1 : 0, "day")
      .toDate();
    nextFilters["endDate"] = moment(filters.PQTDEndDate).toDate();

    setStartDate(nextFilters["startDate"]);
    if (setEndDate) setEndDate(nextFilters["endDate"]);
    setFilters(nextFilters);
  };

  return {
    onClickChartOption,
    onSetAutoCompleteOptions,
    onSelectByTerm,
    onSetCheckboxesGroup,
    onSetYearToDate,
    onSetMonthDate,
    onSetQuarterToDate,
    onSetPriorQuarter,
    onSelectAccount,
    autocompleteOptions,
    filters,
  };
}

function MutateFiltersProvider(props: any) {
  const [filters, setFilters] = React.useState<Filters>(props.filters);
  const value = React.useMemo(() => [filters, setFilters], [filters]);
  return <MutateFiltersContext.Provider value={value} {...props} />;
}

export { MutateFiltersProvider, useMutateFilters };
