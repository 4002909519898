import { useState } from "react";
import { Filters } from "../states/filters"
import { FiltersType, Privileges } from "../enums/enums";
import { CheckboxItem } from "../types/form";
import { privileges } from "../states/privileges";
import { useAtom } from "jotai";

export function useAccordion() {
    const [index, setIndex] = useState<number>(0);
    const [rol] = useAtom(privileges);

    const onSetIndexByClickingFilter = (filterLabel: string) => {
        if (rol) {
            const isAdvisor = rol === Privileges.SALES_REPRESENTATIVE;
            if (!isAdvisor) {
                switch(filterLabel) {
                    case 'Dates': setIndex(0);
                    break;
                    case 'Custom Date Range': setIndex(1);
                    break;
                    case 'Branch Office': setIndex(2);
                    break;
                    case 'Sales Rep': setIndex(3);
                    break;
                    case 'Business Type': setIndex(4);
                    break;
                    case 'Security Type': setIndex(5);
                    break;
                }
            } else {
                switch(filterLabel) {
                    case 'Dates': setIndex(0);
                    break;
                    case 'Custom Date Range': setIndex(1);
                    break;
                    case 'Sales Rep': setIndex(2);
                    break;
                    case 'Business Type': setIndex(3);
                    break;
                    case 'Security Type': setIndex(4);
                    break;
                    case 'Accounts': setIndex(5);
                    break;
                }
            }
        }
    }

    const onSetIndexByClickingCharts = (filters: Filters) => {
        const keys = Object.keys(filters);

        for (let key of keys) {
            switch (key) {
                case FiltersType.BRANCHES: setIndexOnGraphSelection(FiltersType.BRANCHES, filters[FiltersType.BRANCHES]);
                break;
                case FiltersType.ACCOUNTS: setIndexOnGraphSelection(FiltersType.ACCOUNTS, filters[FiltersType.ACCOUNTS]);
                break;
                case FiltersType.REPS: setIndexOnGraphSelection(FiltersType.REPS, filters[FiltersType.REPS]);
                break;
                case FiltersType.BUSINESSES: setIndexOnGraphSelection(FiltersType.BUSINESSES, filters[FiltersType.BUSINESSES]);
                break;
                case FiltersType.SECURITIES: setIndexOnGraphSelection(FiltersType.SECURITIES, filters[FiltersType.SECURITIES]);
                break;
                default: setIndexOnGraphSelection(FiltersType.BRANCHES, filters[FiltersType.BRANCHES]);
            }
        }
    }

    const setIndexOnGraphSelection = (key: FiltersType, items: CheckboxItem[] | undefined) => {
        const checked = items?.some((item) => item.checked);
        
        if (checked) {
            if (rol) {
                const isAdvisor = rol === Privileges.SALES_REPRESENTATIVE;
                if (!isAdvisor) {
                    switch(key) {
                        case 'branches': setIndex(2);
                        break;
                        case 'reps': setIndex(3);
                        break;
                        case 'businesses': setIndex(4);
                        break;
                        case 'securities': setIndex(5);
                        break;
                    }
                } else {
                    switch(key) {
                        case 'reps': setIndex(2);
                        break;
                        case 'businesses': setIndex(3);
                        break;
                        case 'securities': setIndex(4);
                        break;
                        case 'accounts': setIndex(5);
                        break;
                    }
                }
            }
        }
    }

    return { 
        onSetIndexByClickingFilter,
        onSetIndexByClickingCharts,
        index
    }
}