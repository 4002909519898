import React from "react";
import { Flex, Box, VStack, Spinner } from "@chakra-ui/react";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import styled from "styled-components";

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
  return (
    <Flex w="100vw" h="100vh" align="center" justify="center" direction="column" bg="gray.50">
      <Box layerStyle="shadowBox" p={9} width={400}>
        <VStack spacing={9}>
          <BigLogo />
          <Spinner size="xl" color="accent_1_900" />
        </VStack>
      </Box>
    </Flex>
  );
};

const BigLogo = styled(Logo)`
  width: 120px;
  height: 120px;
`;

export default LoginPage;
