import { CheckboxItem } from "../types/form";

export const createCheckboxesFromJSON = (items: any): CheckboxItem[] => {
  return items.map((item: any) => {
    return {
      value: item.id ? item.id : item.name,
      name: item.name
        ? item.name
        : `${`${item.title !== "  " && item.title !== " " && item.title !== "" ? `${item.title} - ` : ``}${
            item.customer
          } - (${item.number})`}`,
      children: item.children
        ? item.children.map((child: any) => {
            return { id: child.id, name: child.name, value: child.sales_rep_id, checked: false };
          })
        : [],
      checked: false,
    };
  });
};
