import React from "react";

interface GraphTransparentBackgroundProps {
  graphConstants: any;
}

const GraphTransparentBackground: React.FC<GraphTransparentBackgroundProps> = ({ graphConstants }) => {
  return <rect x={0} y={0} width={graphConstants.width} height={graphConstants.height} fill="transparent" />;
};

export default GraphTransparentBackground;
