import { Quarters } from "../enums/enums";
import moment from "moment";
import { MixOfBusiness } from "../types/mix-of-business";
import { TopSalesRep } from "../types/top-sales-rep";
import { TopAccounts } from "../types/top-accounts";
import { AssetsUnderManagementLine, AssetsUnderManagementSummary } from "../types/assets-under-management";
import { ProductionDashboardGraph, ProductionDashboardSummary } from "../types/production-dashboard";

export const formatNumber = (value: number, type: string) => {
  const roundUpNumber = Math.ceil(value);

  return roundUpNumber.toLocaleString(type);
};

export const formatValues = (arr: Array<any>, keys: Array<string>) => {
  keys.forEach((key: string) => {
    arr.forEach((data: any) => {
      const roundNumber = Math.round(data[key]);
      if (isNaN(roundNumber)) {
        data[key] = 0;
      }
    });
  });

  return arr;
};

export const formatDates = (date: string) => {
  const dateStructure = date.split("-");
  const filterByDay = dateStructure.length === 3;
  const filterByMonth = dateStructure.length === 2;

  return moment(date).format(filterByMonth ? "M/YY" : filterByDay ? "M/D" : "YYYY");
};

export const removeTimeFromDate = (date: Date) => {
  const momentDate = moment(date).toDate();
  momentDate.setHours(0);
  momentDate.setMinutes(0);
  momentDate.setSeconds(0);
  momentDate.setMilliseconds(0);

  return momentDate;
};

export const getQuarter = () => {
  const currentMonth: number = moment().month();
  let quarter: string = "";

  switch (currentMonth) {
    case 0:
      quarter = Quarters.Q1;
      break;
    case 1:
      quarter = Quarters.Q1;
      break;
    case 2:
      quarter = Quarters.Q1;
      break;
    case 3:
      quarter = Quarters.Q2;
      break;
    case 4:
      quarter = Quarters.Q2;
      break;
    case 5:
      quarter = Quarters.Q2;
      break;
    case 6:
      quarter = Quarters.Q3;
      break;
    case 7:
      quarter = Quarters.Q3;
      break;
    case 8:
      quarter = Quarters.Q3;
      break;
    case 9:
      quarter = Quarters.Q4;
      break;
    case 10:
      quarter = Quarters.Q4;
      break;
    case 11:
      quarter = Quarters.Q4;
      break;
  }

  return quarter;
};

export const hasKey = (
  keys: string[],
  object:
    | MixOfBusiness
    | TopSalesRep
    | TopAccounts
    | AssetsUnderManagementLine
    | AssetsUnderManagementSummary
    | ProductionDashboardGraph
    | ProductionDashboardSummary
    | {}
) => {
  if (keys.length) {
    for (let key of keys) {
      if (key in object) return true;
    }
  }

  return false;
};

export const trimText = (str: string | undefined) => str?.replace(/\s/g, "");
