/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactNode } from "react";
import { theme } from "../theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { WindowSize, windowsSizeAtom } from "../states/dimensions";
import { useAtom } from "jotai";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

interface ProvidersProps {
  children?: ReactNode;
}

const Providers: React.FC<ProvidersProps> = ({ children }) => {
  const [, setWindowSize] = useAtom(windowsSizeAtom);
  const [size, setSize] = useState<WindowSize>({ width: window.innerWidth, height: window.innerHeight });

  const resizeHandler = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setSize({
      width: width,
      height: height,
    });
  };

  useEffect(() => {
    window.onresize = resizeHandler;
    setGlobalSize();
  }, []);

  useEffect(() => {
    setGlobalSize();
  }, [size]);

  const setGlobalSize = () => {
    if (size) {
      setWindowSize(size);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ChakraProvider>
  );
};

export default Providers;
