import { atom } from "jotai";
// import { WINDOW_WIDTH_1280, WINDOW_WIDTH_1366, WINDOW_WIDTH_1600 } from "../theme";

// export type WINDOW_WIDTHS = typeof WINDOW_WIDTH_1280 | typeof WINDOW_WIDTH_1366 | typeof WINDOW_WIDTH_1600;

export interface WindowSize {
  width: number;
  height: number;
}

export const windowsSizeAtom = atom<WindowSize>({ width: window.innerWidth, height: window.innerHeight });
