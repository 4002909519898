import React, { useEffect } from "react";
import { Box, Flex, Select, Spinner, Text } from "@chakra-ui/react";
import { ReactComponent as Clear } from "../../assets/images/clear.svg";
import { theme } from "../../theme";
import { User } from "../../types/user";
import { ImpersonateUserIDKey, ImpersonateUserLabelKey } from "../../core/impersonate";
import { useAtom } from "jotai";
import { userAtom } from "../../states/user";
import useAvailableUsers from "../../hooks/useAvailableUsers";

interface ImpersonateUserProps {
  impersonatedUserID: string | null;
  impersonatedUserLabel: string | null;
}

const ImpersonateUser: React.FC<ImpersonateUserProps> = ({ impersonatedUserID, impersonatedUserLabel }) => {
  const [user] = useAtom(userAtom);
  const { data: availableUsers, refetch: fetchAvailableUsers, isLoading } = useAvailableUsers(user as User);

  useEffect(() => {
    if (user) {
      fetchAvailableUsers().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSelectUser = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = e.target.value;
    const user = availableUsers?.find((user) => user.id === parseInt(userId));

    if (user) {
      localStorage.setItem(ImpersonateUserIDKey, user.id.toString());
      localStorage.setItem(ImpersonateUserLabelKey, getUserLabel(user));
      window.location.reload();
    }
  };

  const onRemoveImpersonation = () => {
    localStorage.removeItem(ImpersonateUserIDKey);
    localStorage.removeItem(ImpersonateUserLabelKey);
    window.location.reload();
  };

  const hasAvailableUsers = availableUsers && availableUsers.length > 0;

  return (
    <>
      {isLoading ? (
        <Spinner size="md" color="accent_1_900" />
      ) : (
        <Flex alignItems="center" grow={1}>
          {impersonatedUserID ? (
            <Flex alignItems="center">
              <Text>
                Viewing As: <strong>{impersonatedUserLabel?.split(" - ")[0]}</strong>
              </Text>
              <Box w={4} h={4} ml={2} cursor="pointer" onClick={onRemoveImpersonation}>
                <Clear fill={theme.colors.accent_1_900} />
              </Box>
            </Flex>
          ) : (
            hasAvailableUsers && (
              <>
                <Text w={70}>View As</Text>
                <Flex grow={1} minW={250}>
                  <Select placeholder="Select user" size="sm" onChange={onSelectUser}>
                    {availableUsers?.map((user) => (
                      <option key={`available-${user.id}`} value={user.id}>
                        {getUserLabel(user)}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </>
            )
          )}
        </Flex>
      )}
    </>
  );
};

const getUserLabel = (user: User) => {
  return `${user.first_name} ${user.last_name} -  ${user.email}`;
};

export default ImpersonateUser;
