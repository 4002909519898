import { FiltersType } from "../enums/enums";
import { Filters } from "../states/filters";
import { CheckboxItem, CheckboxSalesRepChildren } from "../types/form";

export const getFiltersForBackend = (filters: Filters) => {
  // console.log(`fetching data for ${filters.startDate} to ${filters.endDate}`);
  return {
    startDate: filters.startDate,
    endDate: filters.endDate,
    branches: getIdFromCheckedItems(filters, "branches"),
    reps: getIdFromCheckedItems(filters, "reps"),
    businesses: getIdFromCheckedItems(filters, "businesses"),
    securities: getIdFromCheckedItems(filters, "securities"),
    accounts: getIdFromCheckedItems(filters, "accounts"),
  };
};

export const getIdFromCheckedItems = (filters: Filters, key: string) => {
  if (!filters[key]) return [];

  const items = filters[key] as CheckboxItem[];

  let checkedItems: CheckboxItem[] = items.filter((item: CheckboxItem) => item.checked);
  let checkedChildren: CheckboxSalesRepChildren[] = [];
  for (let item of items) {
    if (item && item.children) {
      const checkedChilds = item.children.filter((i: CheckboxSalesRepChildren) => i.checked);
      checkedChildren.push(...checkedChilds);
    }
  }

  if (key === FiltersType.REPS) return checkedChildren.map((item: CheckboxSalesRepChildren) => item.id);

  return checkedItems.map((item: CheckboxItem) => item.value);
};
