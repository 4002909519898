import React, {ReactNode} from "react";
import { VStack } from "@chakra-ui/react";
import { StackProps } from "@chakra-ui/layout";

interface SummaryStackProps {
  stackProps?: StackProps;
  children: ReactNode;
}

const SummaryStack: React.FC<SummaryStackProps> = ({ children, stackProps }) => {
  return (
    <VStack minWidth="100px" align="start" spacing={0.5} {...stackProps}>
      {children}
    </VStack>
  );
};

export default SummaryStack;
