import {theme} from "../theme";
export const getColorForArea = (areaKey: string, keyIndex: number) => {
  // TODO set colors on theme
  const colors = [
    theme.colors.yellow_50,
    theme.colors.secondary900,
    theme.colors.primary900,
    theme.colors.grey_50,
    theme.colors.blue_900,
    theme.colors.accent_1_50,
    theme.colors.grey_1_50,
    theme.colors.orange_900,
    theme.colors.pink_50,
    theme.colors.blue_50,
    theme.colors.accent_3_50
  ];

  switch (areaKey.toLowerCase()) {
    case "boca":
      return colors[0];
    case "austin":
      return colors[1];
    case "fairfield":
      return colors[2];
    case "iselin":
      return colors[3];
    case "guaynabo":
      return colors[4];
    case "bloomington":
      return colors[5];
    case "jupiter":
      return colors[6];
    case "naples":
      return colors[7];
    case "charlotte":
      return colors[8];
    case "haverhill":
      return colors[9];
    case "rockville":
      return colors[10];
  }

  return colors[keyIndex];
};

export const getColorForSecurities = (name: string, keyIndex: number) => {
  const colors = [
    theme.colors.accent_1_900,
    theme.colors.primary900,
    theme.colors.secondary900,
    theme.colors.grey_50,
    theme.colors.blue_900,
    theme.colors.accent_1_50,
    theme.colors.blue_50,
    theme.colors.pink_50,
    theme.colors.yellow_900,
    theme.colors.yellow_50,
    theme.colors.orange_900,
    theme.colors.grey_1_50,
    theme.colors.accent_3_50,
    theme.colors.accent_4_50
  ];

  switch (name) {
    case "Corporate Unit Equities":
      return colors[0];
    case "Limited Partnership":
      return colors[1];
    case "Mutual Funds":
      return colors[2];
    case "Options":
      return colors[3];
    case "Cash & Cash Equivalents":
      return colors[4];
    case "Common Stocks":
      return colors[5];
    case "Rights & Warrants":
      return colors[6];
    case "Municipal Bonds":
      return colors[7];
    case "Preferred Stocks":
      return colors[8];
    case "Unit Investment Trusts":
      return colors[9];
    case "Corporate Bonds & Notes":
      return colors[10];
    case "Government Bonds & Notes":
      return colors[11];
    case "When, As & If Issued Bonds":
      return colors[12];
    case "Corporate Unit Bonds":
      return colors[13];
  }

  return colors[keyIndex];
};

export const getColorForAccounts = (value: string, keyIndex: number) => {
  const colors = [
    theme.colors.primary900,
    theme.colors.accent_1_900,
  ];

  switch (value) {
    case "Fee Based":
      return colors[0];
    case "Brokerage":
      return colors[1];
  }
  return colors[keyIndex];
};
