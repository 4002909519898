import { useState } from "react";
import { useMutateFilters } from "./useMutateFilters";
import { RadioButtonItem } from "../types/form";
import useBackendFilters from "./useBackendFilters";
import moment from "moment";

export function useRadioButtons() {
  const { onSetYearToDate, onSetMonthDate, onSetQuarterToDate, onSetPriorQuarter } = useMutateFilters();
  const { data: filters } = useBackendFilters();
  const currentMonth = moment().month();
  const currentBackendMonth = moment(filters?.endDate).month();

  const radioButtonGroup: RadioButtonItem[] = [
    {
      name: "YTD",
      value: "year to date",
      checked: false,
      action: onSetYearToDate,
      disabled: false,
    },
    {
      name: "MTD",
      value: "month to date",
      checked: false,
      action: currentMonth !== currentBackendMonth ? () => {} : onSetMonthDate,
      disabled: false,
    },
    {
      name: "QTD",
      value: "quarter to date",
      checked: true,
      action: currentMonth !== currentBackendMonth ? () => {} : onSetQuarterToDate,
      disabled: false,
    },
    {
      name: "PriorQTR",
      value: "prior quarter",
      checked: false,
      action: onSetPriorQuarter,
      disabled: false,
    },
  ];
  const [radioButtons] = useState<RadioButtonItem[]>(radioButtonGroup);

  return { radioButtons };
}
