import React from "react";
import { GridRows } from "@visx/grid";

interface HorizontalGridRowsProps {
  yScale: any;
  graphConstants: any;
}

const HorizontalGridRows: React.FC<HorizontalGridRowsProps> = ({ yScale, graphConstants }) => {
  return (
    <GridRows
      left={graphConstants.margin.yAxisMargin}
      scale={yScale}
      width={graphConstants.width - graphConstants.margin.yAxisMargin - graphConstants.margin.left}
      strokeDasharray="4,3"
      strokeWidth={0.5}
      stroke="#979797" // TODO
      strokeOpacity={0.4}
      pointerEvents="none"
      className="row-grid"
    />
  );
};

export default HorizontalGridRows;
