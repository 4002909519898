import React from "react";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { WindowSize, windowsSizeAtom } from "../../states/dimensions";
import { WINDOW_WIDTH_1366, WINDOW_WIDTH_1600 } from "../../theme";

interface PageProps {
  mainComponents: React.ReactNode;
  sidebarComponent: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ mainComponents, sidebarComponent }) => {
  const [windowSize] = useAtom<WindowSize>(windowsSizeAtom);
  let paddingRight = 2;
  let distanceBetweenBoxes = 3;

  if (windowSize.width >= WINDOW_WIDTH_1366) {
    paddingRight = 9;
    distanceBetweenBoxes = 6;
  }

  if (windowSize.width >= WINDOW_WIDTH_1600) {
    paddingRight = 9;
    distanceBetweenBoxes = 9;
  }

  return (
    <Flex bg="accent_4_50" flex={1} pl={distanceBetweenBoxes} pt={distanceBetweenBoxes} pr={paddingRight}>
      <Flex flex={1} direction="column">
        <VStack align="stretch" spacing={distanceBetweenBoxes}>
          {mainComponents}
        </VStack>
      </Flex>
      <Box w={distanceBetweenBoxes} />
      {sidebarComponent}
    </Flex>
  );
};

export { Page };
