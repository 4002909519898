export enum MenuOptions {
  ASSETS_UNDER_MANAGEMENT = "Assets Under Management",
  PRODUCTION_DASHBOARD = "Production Dashboard",
}

export enum FiltersType {
  BRANCHES = "branches",
  BUSINESSES = "businesses",
  REPS = "reps",
  SECURITIES = "securities",
  ACCOUNTS = "accounts",
}

export enum Quarters {
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
}

export enum FiltersName {
  BRANCH_OFFICE = "Branch Office",
  SALES_REP = "Sales Rep",
  BUSINESS_TYPE = "Business Type",
  SECURITY_TYPE = "Security Type",
  ACCOUNTS = "Accounts",
}

/**
 * Manager = Organization Manager
 * Support = Branch Office Manager
 * Advisor = Sales Representative
 */
export enum Privileges {
  ORGANIZATION_MANAGER = "B",
  OFFICE_MANAGER = "O",
  SALES_REPRESENTATIVE = "R",
}

export enum CustomDateRanges {
  YEAR_TO_DATE = "year to date",
  MONTH_TO_DATE = "month to date",
  QUARTER_TO_DATE = "quarter to date",
  PRIOR_QUARTER = "prior quarter",
}
