import React from "react";
import { Flex, Text } from "@chakra-ui/react";

interface GraphHeaderProps {}

const GraphHeader: React.FC<GraphHeaderProps> = () => {
    return (
        <Flex direction="row" layerStyle="sectionBox" justify="space-between" width="100%">
            <Text textStyle="sectionTitle">Mix of Business</Text>
        </Flex>
    );
}

export default GraphHeader;