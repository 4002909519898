import { useQuery } from "react-query";
import axios from "axios";
import { Filters } from "../../states/filters";
import { getAPIURL } from "../../core/api";
import { getFiltersForBackend } from "../../core/filters";

export default function useGraphData(filters: Filters, grouping: string) {
  return useQuery(["assets-under-management-graph", filters, grouping], () => fetch(filters, grouping));
}

/**
 * @api {post} /assets-under-management/graph Graph
 * @apiGroup Assets Under Management
 * @apiSampleRequest off
 *
 * @apiParam {Object} filters
 * @apiParam {Date} filters.startDate
 * @apiParam {Date} filters.endDate
 * @apiParam {String[]} filters.branches="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.reps="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.businesses="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.securities="IDs to select. If empty, all are used."
 * @apiParam {String="branch", "business", "security"} grouping
 *
 * @apiSuccessExample {json} Success-Response:
 * HTTP/1.1 200 OK
 * [
 *    {
 *         "date": "2021-04", // String date in YYYY or YYYY-MM format
 *         "branches":{
 *            "austin":1422000,
 *            "boca":2816459,
 *            "fairfield":4046623,
 *            "iselin":6983156,
 *            "guaynabo":5802605,
 *            "bloomington":3637870,
 *            "jupiter":10376856
 *         },
 *         "movements":{
 *            "deposit":8,
 *            "withdrawal":-11,
 *            "ACAT":-27
 *         }
 *      }
 * ]
 */
const fetch = async (filters: Filters, grouping: string) => {
  const newFilters = getFiltersForBackend(filters);
  const response = await axios.post(getAPIURL("/assets-under-management/graph"), {
    grouping,
    ...newFilters,
  });

  let groupingKey: string;
  switch (grouping) {
    case "branch":
      groupingKey = "branches";
      break;
    case "business":
      groupingKey = "businesses";
      break;
    case "security":
      groupingKey = "securities";
      break;
  }

  const keys: string[] = [];
  response.data.forEach((entry: any) => {
    const entriesKeys = Object.keys(entry[groupingKey]);
    keys.push(...entriesKeys);
  });

  // @ts-ignore
  const uniqueKeys = [...new Set(keys)];

  const filteredData: any[] = [];
  response.data.forEach((entry: any) => {
    const hasKey = Object.entries(entry[groupingKey]).length;
    if (hasKey) {
      filteredData.push(entry);
    }
  });

  const areasData = filteredData.map((item: any) => {
    return { date: item.date, ...item[groupingKey] };
  });

  const movementsData = filteredData.map((item: any) => {
    return { date: item.date, ...item.movements };
  });

  return { areasData, movementsData, keys: uniqueKeys };
};
