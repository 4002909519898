import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import styled from "styled-components";
import theme from "@chakra-ui/theme";
const NoDataFound = () => {
  return (
    <Box>
      <Info>{`No data found`}</Info>
    </Box>
  );
};

const Info = styled(Text)`
  color: ${theme.colors.gray[400]};
`;
export default NoDataFound;
