import React from "react";
import { Flex, Text, Box } from "@chakra-ui/react";
import SummaryStack from "../../components/summary-stack";
import Loader from "../../components/loader";
import { TopAccounts as AccountsType } from "../../types/top-accounts";
import styled from "styled-components";
import { formatNumber } from "../../utils/utils";
import { useAccountSelection } from "../../hooks/useAccountSelection";
import { filtersAtom } from "../../states/filters";
import NoDataFound from "../../components/graph/no-data-found";
import { useAtom } from "jotai";

interface TopAccountsProps {
  data: AccountsType[] | undefined;
  status: string;
  sectionTitle: string;
}

const TopAccounts: React.FC<TopAccountsProps> = ({ data, status, sectionTitle }) => {
  const [filters] = useAtom(filtersAtom);
  const { onSetAccount } = useAccountSelection();

  return (
    <Flex layerStyle="shadowBox" sx={{ width: 185 }}>
      <Text textStyle="sectionTitle">{sectionTitle}</Text>
      <Box mb={5} />
      {status !== "success" ? (
        <Loader flexProps={{ alignItems: "start", mt: "6" }} />
      ) : status === "success" && !data?.length ? (
        <NoDataFound />
      ) : (
        <Flex justify="space-between" direction="column">
          {data?.map((account, index) => (
            <SummaryStack key={`${account}-${index}`}>
              <Wrapper>
                <AccountName
                  textStyle="bodySmallLink"
                  onClick={() => {
                    onSetAccount(
                      filters,
                      account.title,
                      `${account.title} - ${account.customer} - (${account.number})`
                    );
                  }}
                >
                  {account.title.toUpperCase()}
                  {` (${account.number})`}
                </AccountName>
                <Text textStyle="bodyBold">${formatNumber(parseFloat(account.total.toString()), "en-US")}</Text>
              </Wrapper>
            </SummaryStack>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const AccountName = styled(Text)`
  cursor: pointer;
`;
export default TopAccounts;
