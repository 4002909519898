import React from "react";
import { Page } from "../../components/layout/page";
import Summary from "./summary";
import AUMTopSales from "./top-sales";
import GraphLoader from "./graph/graph-loader";
import MixOfBusiness from "./mix-of-business";
import TopAccounts from "./top-accounts";
import { privileges } from "../../states/privileges";
import { Privileges } from "../../enums/enums";
import { useAtom } from "jotai";
import Loader from "../../components/loader";
import { Flex } from "@chakra-ui/react";

interface AssetsUnderManagementProps {}

const AssetsUnderManagement: React.FC<AssetsUnderManagementProps> = () => {
  const [rol] = useAtom(privileges);
  const isAdvisor = rol === Privileges.SALES_REPRESENTATIVE;

  return (
    <Page
      mainComponents={
        <>
          <Summary />
          <GraphLoader />
          <MixOfBusiness />
        </>
      }
      sidebarComponent={!rol ? <SidebarLoader /> : isAdvisor ? <TopAccounts /> : <AUMTopSales />}
    />
  );
};

const SidebarLoader = () => (
  <Flex layerStyle="shadowBox" sx={{ width: 185 }}>
    <Loader flexProps={{ alignItems: "start", mt: "16" }} />
  </Flex>
);

export default AssetsUnderManagement;
