import React from "react";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import PageSwitcher from "./page-switcher";
import HeaderContainer from "./header-container";
import RightControls from "./right-controls";
interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <HeaderContainer>
      <Logo />
      <PageSwitcher />
      <RightControls />
    </HeaderContainer>
  );
};

export default Header;
