import { useQuery } from "react-query";
import axios from "axios";
import { getAPIURL, setupCommonHeaders } from "../core/api";

export default function useLogin(accessToken: string | undefined) {
  return useQuery(["login", accessToken], () => fetch(accessToken as string), { enabled: false });
}

/**
 * @api {post} /login Filters
 *
 * @apiParam {String} access_token
 */
const fetch = async (accessToken: string) => {
  const response = await axios.post(getAPIURL("/login"), { access_token: accessToken });
  const token = response.data.token;

  if (token) {
    localStorage.setItem("token", token);
    setupCommonHeaders(token);
  }
};
