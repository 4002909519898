import { atom } from "jotai";
import { MenuOptions } from "../enums/enums";

const DEFAULT_DASHBOARD_ROUTE = 'DefaultDahsboardOption';

export const currentDashboard = atom<string>(localStorage.getItem(DEFAULT_DASHBOARD_ROUTE) ?? MenuOptions.ASSETS_UNDER_MANAGEMENT);

export const currentDashboardPersistence = atom(
  (get) => get(currentDashboard),
  (get, set, newRoute: string) => {
    set(currentDashboard, newRoute);
    localStorage.setItem(DEFAULT_DASHBOARD_ROUTE, newRoute);
  }
);
