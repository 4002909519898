import React from "react";

interface GraphLabelProps {
  x: number;
  y: number;
  text: string;
  color: string;
}

const GraphLabel: React.FC<GraphLabelProps> = ({ x, y, text, color }) => {
  return <text style={{fontSize: 14, fontWeight: 700}} x={x} y={y} fill={color}>{text}</text>;
};

export default GraphLabel;
