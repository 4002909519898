import React, {ReactNode} from "react";
import { Flex } from "@chakra-ui/react";
import { useLayoutWidth } from "../../hooks/useLayoutWidth";

interface PageContainerProps {
  children: ReactNode;
}

const MainContainer: React.FC<PageContainerProps> = ({ children }) => {
  const width = useLayoutWidth();

  return (
    <Flex w="100%" justify="center">
      <Flex bg="gray.50" width={width}>
        {children}
      </Flex>
    </Flex>
  );
};

export default MainContainer;
