import { useQuery } from "react-query";
import axios from "axios";
import { Filters } from "../../states/filters";
import { TopSalesRep } from "../../types/top-sales-rep";
import { getAPIURL } from "../../core/api";
import { getFiltersForBackend } from "../../core/filters";

export default function useTopSalesReps(filters: Filters) {
  return useQuery(["assets-under-management-top-sales-reps", filters], () => fetch(filters));
}

/**
 * @api {post} /assets-under-management/top-sales-reps Top Sales Reps
 * @apiGroup Assets Under Management
 * @apiSampleRequest off
 *
 * @apiParam {Object} filters
 * @apiParam {Date} filters.startDate
 * @apiParam {Date} filters.endDate
 * @apiParam {String[]} filters.branches="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.reps="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.businesses="IDs to select. If empty, all are used."
 * @apiParam {String[]} filters.securities="IDs to select. If empty, all are used."
 *
 * @apiSuccessExample {json} Success-Response:
 * HTTP/1.1 200 OK
 * [
 *    {
 *      name: 'someone';
 *      total: 5763485768437;
 *    },
 * ]
 */
const fetch = async (filters: Filters): Promise<TopSalesRep[]> => {
  const newFilters = getFiltersForBackend(filters);
  const response = await axios.post(getAPIURL("/assets-under-management/top-sales-reps"), {
    ...newFilters,
  });

  return response.data;
};
